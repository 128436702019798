import { Link, useLocation } from 'react-router-dom'
import { ListTransaksi as ListTrans, TabTransaksi } from './Api/Deis'
import { useEffect, useState, Fragment, Component } from 'react'
import TitleBar from './Components/Menu/TitleBar'
import { subdomain, numberFormatReverse, satker } from './Api/Value'
import { Select, SelectIcon, Text, Password } from './Components/Form/Input'
import { IconOutlineCard } from './Components/Container/ColorCard'
import { KeyIcon, ChartBarIcon, ArrowTrendingUpIcon, CalendarIcon } from '@heroicons/react/24/outline'
import { PrimaryButton } from './Components/Form/Button'
import { Tab } from '@headlessui/react'
import LineChartPenyerapan from './Components/Tabs/LineChartPenyerapan'
import BarChartPenyerapan from './Components/Tabs/BarChartPenyerapan'
import Datatabel from './Components/Tabs/Datatabel'
import {connect} from "react-redux";

class GrafikPenyerapan extends Component{
    state = {
        render: true
    }
    componentDidMount = () => {
        
    }
    componentDidUpdate = (prevProps) => {
    
    }
    render(){
        if(this.state.render === false){
            return null
        }
        return (
            <div>
               <TitleBar 
                    routing = { [
                        {
                            route: subdomain + "/dashboard",
                            name: "Dashboard"
                        }
                    ] }
                    title   = "Grafik Penyerapan"
                />
                <div className="w-full mt-2">
                    <Tab.Group >
                        <Tab.List className="grid grid-cols-3 gap-2 justify-between text-sm">
                            
                            <Tab className={({ selected  }) => `p-2 ${selected ? "bg-blue-500 text-slate-50" : "bg-white/50 dark:bg-black/50 dark:text-slate-50 text-slate-800"}  rounded-md flex justify-center gap-1`}>
                                <div className="pt-[2px]">
                                    <ArrowTrendingUpIcon className="w-5 h-5"/>                            
                                </div>
                                <div className="pt-[2px]">
                                    Line Chart
                                </div>
                            </Tab>
                            <Tab className={({ selected  }) => `p-2 ${selected ? "bg-blue-500 text-slate-50" : "bg-white/50 dark:bg-black/50 dark:text-slate-50 text-slate-800"}  rounded-md flex justify-center gap-1`}>
                                <div className="pt-[2px]">
                                    <ChartBarIcon className="w-5 h-5 "/>                            
                                </div>
                                <div className="pt-[2px]">
                                    Column Chart
                                </div>
                            </Tab>   
                            <Tab className={({ selected  }) => `p-2 ${selected ? "bg-blue-500 text-slate-50" : "bg-white/50 dark:bg-black/50 dark:text-slate-50 text-slate-800"}  rounded-md flex justify-center gap-1`}>
                                <div className="pt-[2px]">
                                    <CalendarIcon className="w-5 h-5"/>                            
                                </div>
                                <div className="pt-[2px]">
                                    Data Tabel
                                </div>
                            </Tab>   
                        </Tab.List>
                        <Tab.Panels>
                            <Tab.Panel>
                                <LineChartPenyerapan isDark={ this.props.isDark }/>
                            </Tab.Panel>
                            <Tab.Panel>
                                <BarChartPenyerapan isDark={ this.props.isDark } />
                            </Tab.Panel>
                            <Tab.Panel>
                                <Datatabel isDark={ this.props.isDark } />
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                    
                </div>
                
            </div>
        )
    }
}

const App = (props) => {
    return <GrafikPenyerapan isDark={props.isDark}/>
}
const mapStateProps = state => ({
    isDark: state.globalReducer.isDark
})
export default connect(mapStateProps, {  })(App)