import {
  RouterProvider,
  BrowserRouter as Router,
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import { useEffect, Component } from 'react'
import { toggleDarkMode, toggleNav } from './store/actions/globalActions'
import Nav from './Components/Menu/Nav'
import ListTransaksi from './ListTransaksi'
import PosturAnggaran from './PosturAnggaran'
import KonfigurasiUser from './KonfigurasiUser'
import KonfigurasiApi from './KonfigurasiApi'
import Profile from './Profile'
import GrafikPenyerapan from './GrafikPenyerapan'
import Penyerapan from './Penyerapan'
import RealisasiPerKonkin from './RealisasiPerKonkin'
import Login from './Login'
import Dashboard from './Dashboard'
import { connect } from "react-redux"
import ManajemenRole from "./ManajemenRole"
import toast,{ Toaster } from 'react-hot-toast'
import GlobalParameter from './GlobalParameter'
import ManajemenRenkas from './ManajemenRenkas'
import { getCurrentUserData } from './Api/User'
import JadwalRenkas from './JadwalRenkas'
import GagalLogin from './GagalLogin'
import PrivacyPolicy from './PrivacyPolicy'
const Rute = ({ isDark, toggleDarkMode, toggleNav, isLogin, listNav }) => {
  const onLoad = async () => {
    const d = await getCurrentUserData()
    if(d.code === 200){
      if(d.res?.data){
        try {
          const json = (d.res.data[0]["rolemenu"])
          const menu = typeof json === "object" ? json : JSON.parse(json)

          let par = [];

          for(let m of menu.top){
            par.push(m.route)
            for(let p of m.childs){
              par.push(p.route)
            }
          }
          for(let m of menu.bottom){
            par.push(m.route)
            for(let p of m.childs){
              par.push(p.route)
            }
          }
         
          toggleNav({menu: menu, str: par, user: { ...d.res.data[0], rolemenu: null, colorIndex: Math.floor(Math.random() * 4) } })
        } catch (error) {
          console.log(error)
          toast.error("Gagal Mendapatkan Menu")          
        }
      }else{
        toast.error("Gagal Mendapatkan User Data")
      }

    }
  }
  useEffect(() => {
  //  onLoad()
    return () => {
      
    };
  }, []);
  return (
    <div>
        <Toaster
          position="bottom-right"
          reverseOrder={false}
          toastOptions={{
            className: '',
            duration: 5000,
            style: {
              background: (isDark === true) ? "#334155" : '#F8FAFC',
              color: (isDark === true) ? '#fff' : "#334155",
            },
          }}
        />
        <Router>

        {
            isLogin ?
                <Nav isDark={ isDark } listNav={ listNav } toggleDark={ () => toggleDarkMode(!isDark) }/>
            :
                null 
        }
        <div>
            <div className={ isLogin ? " pt-[65px] px-1  sm:pt-[10px] sm:pr-[10px] min-h-screen sm:pl-[85px]" : null} id={ isLogin ? "bg-in" : null}>
            <div className={ isLogin ? "w-full  rounded-md bg-opacity-50 backdrop-blur-xl bg-white dark:bg-black/30 p-2 min-h-[96vh]" : null}>
                  
                      <Routes>
                        <Route path="/login" element={<Login isDark={ isDark } toggleDark={ () => toggleDarkMode(!isDark) }/>} />    
                        <Route path="/login/privacy_policy" element={<PrivacyPolicy />} />    
                        <Route path="/login/error" element={<GagalLogin />} />    
       {
        /*                 
                          <Route path="/app/grafikPenyerapan" element={ <GrafikPenyerapan /> } />
                          <Route path="/app/manajemenRenkas" element={ <ManajemenRenkas /> } />
                          <Route path="/app/globalParameter" element={ <GlobalParameter /> } />
                          <Route path="/app/konfigurasiRole" element={ <ManajemenRole /> } />
                          <Route path="/app/profile/" element={<Profile />} />
                          <Route path="/app/konfigurasiApi/" element={<KonfigurasiApi />} />
                          <Route path="/app/konfigurasiUser/" element={<KonfigurasiUser />} />
                          <Route path="/app/posturAnggaran/" element={<PosturAnggaran />} />
                          <Route path="/app/tabelPenyerapan/" element={<Penyerapan />} />
                          <Route path="/app/realisasiPerKonkin/:sp2d/:tgl_sp2d/:minggu_ke/:satker" element={<RealisasiPerKonkin />} />
                          <Route path="/app/listTransaksi/:type/:type/:type/:type" element={<ListTransaksi />} />
                          <Route path="/app/" element={ <Dashboard />}/>
                          <Route path="/app/dashboard" element={ <Dashboard />}/>
                          <Route path="/app/jadwalRenkas" element={ <JadwalRenkas /> } />
      */
                        }
                    </Routes>
            </div>
            </div>
        </div>
        </Router>

        </div>
    
    )
    }

const mapStateToProps = state => ({
  isDark: state.globalReducer.isDark,
  isLogin: state.globalReducer.isLogin,
  listNav: state.globalReducer.listNav

})

export default connect(mapStateToProps,{ toggleDarkMode, toggleNav })(Rute);