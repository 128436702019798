import { Bars3BottomLeftIcon, UserIcon, SunIcon, ChevronUpIcon, ArrowLeftIcon,
  AdjustmentsHorizontalIcon,
  BuildingOffice2Icon,
  Cog6ToothIcon,
  ArrowUturnLeftIcon
} from '@heroicons/react/24/outline'
import { MoonIcon } from '@heroicons/react/24/solid'
import { Dialog, Transition, Menu  } from '@headlessui/react'
import { useState, Fragment } from 'react'
import { MenuIcons, randomColor, backend, subdomain } from '../../Api/Value'
import { NavLink } from 'react-router-dom'

const MobileNav = ({ isDark, toggleDark, list, user }) => {
    let [isOpen, setIsOpen] = useState(false)

    function closeModal() {
      
        setIsOpen(false)
    }
    
    function openModal() {
        setIsOpen(true)
    }
    
    return (
        <div>
        <div className="bg-white dark:bg-slate-700 z-10 shadow-md fixed top-0 left-0 w-full p-2  sm:hidden flex justify-between">
            <div className="flex gap-2 pt-[4px]">
                <Bars3BottomLeftIcon onClick={() => setIsOpen(true) } className="w-7 h-7 dark:text-slate-200 text-slate-500" />
                <div className="text-slate-600 font-bold pt-1 dark:text-slate-200">DEIS 2.0</div>
            </div>
            <div className="flex gap-3">
                <div className="pt-2">
                    {
                        isDark === true ?
                        <MoonIcon onClick={ toggleDark } className="w-6 h-6 text-yellow-300"/>
                        :
                        <SunIcon onClick={ toggleDark } className="w-6 h-6 text-slate-600"/>

                    }
                </div>

                    <ProfileMenu user={ user } />


            </div>
        </div>
        <ModalNav 
            isOpen={ isOpen }
            setIsOpen = { setIsOpen }
            openModal = { openModal }
            closeModal = { closeModal }
            list  = { list }
        />
        </div>
    )
}

const ModalNav = ({ isOpen, setIsOpen, openModal, closeModal, list }) => {
  
    return (
        <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={closeModal}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-1 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-md transform overflow-hidden min-h-[98vh] rounded-2xl bg-white dark:bg-slate-800 p-6 text-left align-middle shadow-xl transition-all">
                  
                  <div className="w-full relative text-center font-bold dark:text-slate-200">
                      Menu   
                      <button className=" absolute left-2 cursor-pointer" onClick={() => closeModal() } >
                        <ArrowLeftIcon  className="w-6"/> 
                      </button>
                  </div>
                  { 
                    list.top.map((v, k) => {
                        const isChild = v.childs.length > 0
                        const theIcon = MenuIcons(v.route)
                        return isChild ? 
                        (
                            <div key={ k } >
                                <ChildMenu index={ k } icon={ theIcon } name={ v.name }>
                                    {
                                        v.childs.map((c, i) => {
                                            const childIcon = MenuIcons(c.route)
                                            return(
                                              <NavLink to={ c.route } key={ i } >
                                                <div className="flex" onClick={ closeModal }>
                                                  <div className="w-9"></div>
                                                  <div  className="h-14 w-full border-b-[1px] dark:border-slate-700 border-slate-100 text-sm flex gap-2 p-[4px] dark:hover:bg-slate-800 hover:bg-slate-50/40">
                                                      <div className="pt-2">{ childIcon }</div>
                                                      <div className="pt-[14px] dark:text-slate-50 transition ease-in delay-150">
                                                          { c.name } 
                                                      </div>
                                                  </div>
                                                </div>
                                              </NavLink>
                                            )
                                        })
                                    }
                                </ChildMenu>
                            </div>
                        )
                        : 
                        (
                          <NavLink to={ v.route } key={ k } >
                            <div onClick={ closeModal } className={`h-14 w-full border-b-[1px] dark:border-slate-700 border-slate-100 text-sm flex gap-2 p-[4px] dark:hover:bg-slate-800 hover:bg-slate-50/40 `}>
                              { theIcon }
                            
                                    <div className="pt-[14px] dark:text-slate-50 transition ease-in delay-150">
                                        { v.name }
                                    </div>
                            
                            </div>        
                          </NavLink>
                        )
                    })
                }
                <div className="w-full font-bold text-center mt-5 dark:text-slate-50">
                  Sistem
                </div>
                
                { 
                  list.bottom.map((v, k) => {
                      const isChild = v.childs.length > 0
                      const theIcon = MenuIcons(v.route)
                      return isChild ? 
                      (
                          <div key={ k } >
                              <ChildMenu index={ k } icon={ theIcon } name={ v.name }>
                                  
                                  {
                                      v.childs.map((c, i) => {
                                          const childIcon = MenuIcons(c.route)
                                          return(
                                            <NavLink to={ c.route } key={ i } >
                                              <div onClick={ closeModal } className="flex">
                                                <div className="w-9"></div>
                                                <div  className="h-14 w-full border-b-[1px] dark:border-slate-700 border-slate-100 text-sm flex gap-2 p-[4px] dark:hover:bg-slate-800 hover:bg-slate-50/40">
                                                    <div className="pt-2">{ childIcon }</div>
                                                    <div className="pt-[14px] dark:text-slate-50 transition ease-in delay-150">
                                                        { c.name }
                                                    </div>
                                                </div>
                                              </div>
                                            </NavLink>
                                          )
                                      })
                                  }
                              </ChildMenu>
                          </div>
                      )
                      : 
                      (
                          <div key={ k } className={`h-14 w-full border-b-[1px] dark:border-slate-700 border-slate-100 text-sm flex gap-2 p-[4px] dark:hover:bg-slate-800 hover:bg-slate-50/40 `}>
                            { theIcon }
                          
                                  <div className="pt-[14px] dark:text-slate-50 transition ease-in delay-150">
                                      { v.name }
                                  </div>
                          
                          </div>        
                      )
                  })
              }
  

                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    )
}

const ChildMenu = ({ icon, name, children }) => {
  const [isShowing, setIsShowing] = useState(false)
  return (
    <div>
      <div className="h-14 p-[4px] border-b-[1px] dark:border-slate-700 border-slate-100">
        <div className="flex gap-2 w-full  cursor-pointer justify-between" onClick={ () => setIsShowing(!isShowing) }>
          <div className="pt-[10px] gap-2  dark:text-slate-50 text-sm flex justify-between">
              
              { icon }

              <div className="pt-[3px]">
                { name }
              </div>
            
          </div>
          <div className="w-10 pt-[12px]" >
            <ChevronUpIcon className={`text-slate-500 w-5 ${ isShowing ? "" : "rotate-180" } transition-all duration-300`}/>
          </div>
        </div>
      </div>
      <Transition
        show={isShowing}
        enter="transition-opacity duration-300"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity duration-300"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
      >
        {
          children
        }
      </Transition>
    </div>
  )
}
const ProfileMenu = ({ user }) => {
  return (
    <Menu>
      <Menu.Button>
          {
            user ?
            <Fragment>
                {
                    user.name ?
                      <div className="w-10 h-10 relative rounded-full text-[20px] text-slate-50" style={{ background: randomColor(user.colorIndex) }}>
                          <div className="absolute top-[10%] text-center w-full">
                              { `${user.name}`.charAt(0).toUpperCase() }
                          </div>
                      </div>
                    :
                        <div className="w-10 h-10 rounded-full bg-slate-100 dark:bg-slate-400 p-[5px] pl-[7px]">
                            <UserIcon className="w-[26px] text-slate-700 dark:text-slate-800" />
                        </div>
                }
                
            </Fragment>
            :

            <div className="w-10 h-10 rounded-full bg-slate-100 dark:bg-slate-400 p-[5px] pl-[7px]">
                <UserIcon className="w-[26px] text-slate-700 dark:text-slate-800" />
            </div>
          }
          
      </Menu.Button>
      <Transition
          as={Fragment}
          enter="transition ease-out duration-100"
          enterFrom="transform opacity-0 scale-95"
          enterTo="transform opacity-100 scale-100"
          leave="transition ease-in duration-75"
          leaveFrom="transform opacity-100 scale-100"
          leaveTo="transform opacity-0 scale-95"
        >
          <Menu.Items className="absolute right-2 z-40 mt-14 w-56 origin-top-right divide-y divide-gray-100 rounded-md dark:bg-slate-700 bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
            <div className="px-1 py-1 ">
              <Menu.Item>
                {({ active }) => (
                  <NavLink to={`${subdomain}/profile`}>
                  <button
                    className={`${
                      active ? 'bg-gray-100 dark:bg-slate-900 ' : 'text-gray-900 dark:text-slate-50'
                    } group flex w-full items-center dark:text-slate-50 rounded-md px-3 py-3 text-sm`}
                  >
                    {active ? (
                      <UserIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <UserIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                    Profil
                  </button>
                  </NavLink>
                )}
              </Menu.Item>
              <Menu.Item>
                {({ active }) => (
                <a href={`${backend}/api/logout`}>

                  <button
                    className={`${
                      active ? 'bg-gray-100 dark:bg-slate-900 ' : 'text-gray-900 dark:text-slate-50'
                    } group flex w-full items-center dark:text-slate-50 rounded-md px-3 py-3 text-sm`}
                  >
                    {active ? (
                      <ArrowUturnLeftIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    ) : (
                      <ArrowUturnLeftIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                    )}
                    Logout
                  </button>
                  </a>
                )}
              </Menu.Item>
            </div>
          </Menu.Items>
        </Transition>
      
    </Menu>
  )
}
export default MobileNav