import { Popover, Switch } from '@headlessui/react'
import { ChevronDownIcon, PencilIcon, TrashIcon, LockOpenIcon } from '@heroicons/react/24/outline'


export const LoginButton = () => {
    return (
        <button className="bg-indigo-600 p-2 w-full mt-2 text-white rounded-md font-bold">
            Masuk
        </button>
    )
}
export const PrimaryButton = ({ children, onClick, className="bg-blue-700" }) => {
    return (
        <button className={`text-sm shadow-md hover:opacity-80 transition-all duration-200 active:shadow-none active:opacity-40 text-slate-50 rounded-md py-[6px] px-7 ${ className }`} onClick={ onClick }>
            { children }
        </button>
    )
}
export const Button = ({ children, onClick, className="bg-blue-700" }) => {
    return (
        <button className={` shadow-md hover:opacity-80 transition-all duration-200 active:shadow-none active:opacity-40  rounded-md   ${ className }`} onClick={ onClick }>
            { children }
        </button>
    )
}
export const PopButton = ({ children, panelClass, className="" }) => {
    return (
        <Popover className="relative">
            <Popover.Button className={`flex shadow-md hover:opacity-80 transition-all duration-200 active:shadow-none active:opacity-40 bg-slate-50 border-blue-500 border-2 py-[5px] px-3 gap-3 rounded-md text-slate-600 ${className}`}>
                <div>
                    Kolom
                </div>    
                <div className="w-5 pt-[2px]">
                    <ChevronDownIcon/>
                </div>
            </Popover.Button>
    
            <Popover.Panel className={`absolute z-10 ${panelClass}`}>
                    { children }
            </Popover.Panel>
        </Popover>
    )
}

export const SwitchButton = ({ enabled, setEnabled }) => {
    return (
        <Switch
            checked={enabled}
            onChange={setEnabled}
            className={`${
                enabled ? 'bg-blue-600' : 'bg-gray-200 dark:bg-slate-900'
            } relative inline-flex h-6 w-11 items-center rounded-full`}
            >
            <span className="sr-only">Enable notifications</span>
            <span
                className={`${
                enabled ? 'translate-x-6 bg-white ' : 'translate-x-1 bg-white dark:bg-slate-700'
                } inline-block h-4 w-4 transform rounded-full transition`}
            />
    </Switch>
    )
}

export const EditBtn = ({ onClick }) => {
    return (
        <button onClick={ onClick } className="w-8 h-8  p-1 hover:opacity-90 active:opacity-50 rounded-full dark:border-yellow-300 border-yellow-500 border-2 text-yellow-500 dark:text-yellow-50 shadow-md">
            <PencilIcon />
        </button>
    )
}

export const HapusBtn = ({ onClick }) => {
    return (
        <button onClick={ onClick } className="w-8 h-8 p-1 hover:opacity-90 active:opacity-50 rounded-full border-red-500 border-2 text-red-500 shadow-md">
            <TrashIcon />
        </button>
    )
}
export const OpenBtn = ({ onClick }) => {
    return (
        <button onClick={ onClick } className="w-8 h-8 p-1 hover:opacity-90 active:opacity-50 rounded-full border-green-500 border-2 text-green-500 shadow-md">
            <LockOpenIcon />
        </button>
    )
}