
export const toggleDarkMode = (props) => {
    return ({
        type: "TOGGLE_DARK_MODE",
        payload: props
    })
}

export const toggleLogin = (props) => {
    return ({
        type: "TOGGLE_LOGIN",
        payload: props
    })
}

export const toggleNav = (props) => {
    return ({
        type: "TOGGLE_NAV",
        payload: props 
    })
}