import { Link, useLocation } from 'react-router-dom'
import { ListTransaksi as ListTrans, TabTransaksi } from './Api/Deis'
import { useEffect, useState, Fragment, Component } from 'react'
import TitleBar from './Components/Menu/TitleBar'
import { subdomain, numberFormatReverse, satker } from './Api/Value'
import Datatable, { ColSort } from './Components/Table/Datatable'
import { Select, SelectIcon, Text, Star, Slider } from './Components/Form/Input'
import { IconOutlineCard } from './Components/Container/ColorCard'
import { CreditCardIcon, UserIcon, BuildingLibraryIcon, KeyIcon, BriefcaseIcon } from '@heroicons/react/24/outline'
import { PrimaryButton, EditBtn, HapusBtn } from './Components/Form/Button'
import Modal, { ModalDelete } from './Components/Container/Modal'
import { getAllSatker } from './Api/Satker'
import { createUser, getAllUser, deleteUser, setUser } from './Api/User'
import { getAllRole } from './Api/Role'
import toast from 'react-hot-toast'
const initForm = JSON.stringify({
    name: "",
    username: "",
    password: "",
    retype_password: "",
    role: "",
    kodesatker: "",
    active: true
});
class KonfigurasiUser extends Component{
    state = {
        isOpen: false,
        listSatker: [],
        listRole: [],
        
        form: JSON.parse(initForm),
        data: [],
        loader: false,
        id_delete: null,
        id_update: null
    }
    componentDidMount = () => {
       
        this.initData()
    }

    onLoad = async () => {
        const req =  await getAllUser()

        if(req.code === 200){
            if(req.res.data){
                if(req.res.data.length !== undefined){
                    const listSatker = this.state.listSatker
                    
                    this.setState({
                        data: req.res.data.map((x) => {
                            const cari = listSatker.filter((v) => v.kodesatker === x.kodesatker)
                            x["namasatker"] = (cari.length > 0) ? cari[0]["namasatker"] : ""
                            return x
                        })
                    })
                }
            }
        }
    }
    initData = async () => {
        const satker = await getAllSatker()
        let param = { listSatker: [], listRole: [] }
        // jika kode req 200
        if(satker.code === 200){
            if(satker.res.data){
                if(satker.res.data.length !== undefined){
                        param["listSatker"] = satker.res.data
                }
            }
        }
        const role = await getAllRole(false)
        console.log(role)
        // jika kode req 200 
        if(role.code === 200){
            if(role.res.data){

                if(role.res.data.length !== undefined){
                    param["listRole"] = role.res.data
                }
            }
        }
        this.setState(param, () => this.onLoad())
    }
    openModal  = () => {
        this.setState({
            isOpen: true
        })
    }
    closeModal = () => {
        this.setState({
            isOpen: false
        })
    }
    handleChange = (e) => {
        const nam = e.target.name
        const val = e.target.value 
        let form = this.state.form 
        form[nam] = val 
        this.setState({
            form: form
        })
    }
    setEnabled = () => {
        let form = this.state.form 
        form["active"] = !form["active"] 
        this.setState({
            form: form
        })
    }
    handleSubmit = async (direct=false) => {
        const form      = this.state.form 
        const id_update = this.state.id_update
        let required    = (id_update === null) ? ["username", "password", "name", "retype_password", "role", "satker"] : ["username", "role", "satker", "name"]
        let validated  = true
        for(let r of required){
            if(form[r] === ""){
                const key = `${r}`.replaceAll("_", " ")
                toast.error(`${key} required.`)
                validated = false
            }
        }
        if(id_update !== null || form.password !== "" || form.retype_password !== ""){
            if(form.password !== form.retype_password){
                validated = false
                toast.error("Password tidak sama.")
            }
        }
        
        if(this.state.loader === true){
            return false
        }
        if(validated){
            this.setState({
                loader: true
            })
        }

        if(id_update === null && validated){
            
            const req = await createUser(form)
            if(req.code === 200){
                if(req.res.message){
                    toast.success(`${req.res.message}`)
                    this.setState({
                        loader: false,
                        isOpen: false,
                        form: JSON.parse(initForm)
                    }, () => this.onLoad())
                }else{
                    this.setState({ loader: false })
                }
            }else{
                this.setState({ loader: false })
            }
        }else if(id_update !== null && validated){
            const req = await setUser({ ...form, id: this.state.id_update})
            if(req.code === 200){
                if(req.res.message){
                    if(direct === false){
                        toast.success(`${req.res.message}`)                        
                    }
                    this.setState({
                        loader: false,
                        isOpen: false,
                        id_update: null,
                        form: JSON.parse(initForm)
                    }, () => this.onLoad())
                }else{
                    this.setState({ loader: false })
                }
            }else{
                this.setState({ loader: false })
            }
        }
    }
    closeModalDelete = () => this.setState({ id_delete: null })
    submitDelete = async () => {
        this.setState({ loader: true })
        const req = await deleteUser({ id: this.state.id_delete })

        if(req.code === 200){
            if(req.res.message){
                toast.success(`${req.res.message}`)
                this.setState({
                    loader: false,
                    id_delete: null
                }, () => this.onLoad())
            }else{
                this.setState({ loader: false })
            }
        }else{
            this.setState({ loader: false })
        }
    }
    openModalDelete= (id) => this.setState({ id_delete: id })
    openModalEdit =(id) => {
        const data = this.state.data.filter((x) => x.id === id)
        let form = this.state.form 
        if(data.length > 0){
            const val = data[0]
            form["username"] = val.username
            form["name"]    = val.name
            form["kodesatker"] = val.kodesatker
            form["role"] = val.role
            form["active"] = val.active
            this.setState({
                isOpen: true, 
                form: form,
                id_update: val.id
            })
        }
    } 
    setEnabledDirect = (id) => {
        const data = this.state.data.filter((x) => x.id === id)
        let form   = this.state.form
        if(data.length > 0){
            const val = data[0]
            form["username"] = val.username
            form["name"]    = val.name
            form["kodesatker"] = val.kodesatker
            form["role"] = val.role
            form["active"] = !val.active
            this.setState({
                form: form,
                id_update: val.id 
            }, () => this.handleSubmit(true))
        }
    }
    render(){
        return (
            <div>
                <TitleBar
                    routing = { [
                        {
                            route: subdomain + "/dashboard",
                            name: "Dashboard"
                        }
                    ] }
                    title   = "Manajemen User"
                />
                <Datatable 
                    data = {this.state.data}
                    Thead= { Thead }
                    Topleft = {
                        <PrimaryButton onClick={ this.openModal } className="text-sm bg-blue-600 h-9 mt-[1px] ml-2">
                            Tambah
                        </PrimaryButton>
                    }
                    rows = {(x, i) => {
                        return (
                            <tr key={i}>
                                <ColBody>
                                    { x.id }
                                </ColBody>
                                <ColBody>
                                    { x.username }
                                </ColBody>
                                <ColBody>
                                    { x.name }
                                </ColBody>  
                                <ColBody>
                                    { x.namasatker }
                                </ColBody>
                                <ColBody>
                                    { x.role }
                                </ColBody>
                                <ColBody>
                                    <Slider 
                                        title = ""
                                        enabled = { x.active }
                                        setEnabled = { () => this.setEnabledDirect(x.id) }
                                    />
                                </ColBody>
                                <ColBody>
                                    <div className="flex gap-2 justify-center">
                                        <EditBtn onClick={() => this.openModalEdit(x.id)}/>
                                        <HapusBtn onClick={() => this.openModalDelete(x.id)} />
                                    </div>
                                    
                                </ColBody>
                            </tr>
                        )
                        

                    }}
                />
                <ModalDelete isOpen={  this.state.id_delete !== null } closeModal={ this.closeModalDelete } onSubmit={ this.submitDelete } />
                <Modal closeModal={ this.closeModal } loader={this.state.loader} onSubmit={ this.handleSubmit } isOpen={this.state.isOpen} title="Form User">
                    <div>
                        <form action="#" autoComplete="off" autofill="off">
                        <div className="mb-3">
                            <div className="mb-1 text-sm text-slate-700 dark:text-slate-50">
                                NIP 
                                <Star />
                            </div>
                            <Text defaultValue={ this.state.form.username } onChange={ this.handleChange } Icon={ CreditCardIcon } name="username" placeholder="NIP" />
                        </div>
                        <div className="mb-3">
                            <div className="mb-1 text-sm text-slate-700 dark:text-slate-50">
                                Nama
                                <Star />
                            </div>
                            <Text defaultValue={ this.state.form.name } onChange={ this.handleChange } Icon={ UserIcon } name="name" placeholder="Nama" />
                        </div>
                        <div className="mb-3">
                            <div className="mb-1 text-sm text-slate-700 dark:text-slate-50">
                                Password
                                { this.state.id_update === null ? <Star /> : null }
                            </div>
                            <Text defaultValue={ this.state.form.password } onChange={ this.handleChange } type="password" name="password" Icon={ KeyIcon } placeholder="Password" />
                        </div>
                        <div className="mb-3">
                            <div className="mb-1 text-sm text-slate-700 dark:text-slate-50">
                                Retype Password
                                { this.state.id_update === null ? <Star /> : null }
                            </div>
                            <Text defaultValue={ this.state.form.retype_password } onChange={ this.handleChange } type="password" name="retype_password" Icon={ KeyIcon } placeholder="Password" />
                        </div>
                        <div className="mb-3">
                            <div className="mb-1 text-sm text-slate-700 dark:text-slate-50">Role <Star /></div>
                            <SelectIcon 
                            name="role"
                            defaultValue={ this.state.form.role }
                            onChange={ this.handleChange } Icon={ BriefcaseIcon }>
                                <option value="">Pilih Role</option>
                                {
                                    this.state.listRole.map((x) => {
                                        return <option key={ x.id } value={ x.rolename }> { x.rolename } </option>
                                    })
                                }
                            </SelectIcon>
                        </div>
                        
                        <div className="mb-3">
                            <div className="mb-1 text-sm text-slate-700 dark:text-slate-50">Satker <Star /></div>
                            <SelectIcon
                            name="kodesatker"
                            defaultValue={ this.state.form.kodesatker }
                            onChange={ this.handleChange } Icon={ BuildingLibraryIcon }>
                                <option value="">Pilih Satker</option>
                                {
                                    this.state.listSatker.map((x) => {
                                        return <option key={ x.kodesatker } value={ x.kodesatker }> { x.namasatker } </option>
                                    })
                                }
                            </SelectIcon>
                        </div>
                        <div>
                            <Slider 
                                title = "Active"
                                enabled = { this.state.form.active }
                                setEnabled = { this.setEnabled }
                            />
                        </div>
                        <div className="mb-2">
                            <div className="mb-1 text-sm"></div>
                        </div>
                        </form>
                    </div>
                </Modal>
            </div>
        )
    }
}

const Thead = () => {
    return (
        <tr>
            <td className="bg-slate-100 dark:bg-slate-700 dark:border-slate-800 p-3 border-r-[1px] text-sm rounded-l-md">No</td>
            <td className="bg-slate-100 dark:bg-slate-700 dark:border-slate-800 p-3 border-r-[1px] text-sm ">Username</td>
            <td className="bg-slate-100 dark:bg-slate-700 dark:border-slate-800 p-3 border-r-[1px] text-sm ">Name</td>
            <td className="bg-slate-100 dark:bg-slate-700 dark:border-slate-800 p-3 border-r-[1px] text-sm ">Satker</td>
            <td className="bg-slate-100 dark:bg-slate-700 dark:border-slate-800 p-3 border-r-[1px] text-sm ">Role</td>
            <td className="bg-slate-100 dark:bg-slate-700 dark:border-slate-800 p-3 border-r-[1px] text-sm ">Active</td>
            <td className="bg-slate-100 dark:bg-slate-700 dark:border-slate-800 p-3 border-r-[1px] text-sm rounded-r-md text-center">Action</td>
        </tr>
    )
}
const App = () => {

    return <KonfigurasiUser />
}


const ColBody = ({ className, children }) => {
    return  <td className={`text-slate-700 px-3 py-2 border-[1px] border-slate-100 dark:border-slate-900 dark:text-slate-50 ${className} text-sm`}>
        { children }
    </td>
}
export default App