import { handleBgLogin } from '../../Api/Value'
const initialState = {
    isDark: localStorage.getItem("dark") === "1",
    isLogin: false,
    listNav: { top: [], bottom: [] },
    menuDash: [],
    user: {}
}

const globalReducer =  (state = initialState, action) => {
    const { type, payload } = action
    switch (type) {
        case "TOGGLE_NAV":
                  
            return { ...state, isLogin: true, listNav: payload.menu, menuDash: payload.str, user: payload.user }
            break;
        case "TOGGLE_DARK_MODE":
            // get initial state 
            const bool = !state.isDark;
            // set to dark 
            localStorage.setItem("dark", (bool) ? 1 : 0)
            // trigger theme
            const el = document.getElementsByTagName("html")[0].classList
            if(bool === true){
                el.add("dark")
            } else {
                el.remove("dark")
            }
            handleBgLogin(bool)
            return { ...state, isDark: bool }
            break;
    
        default:
            return { ...state }
            break;
    }
}

export default globalReducer