import { useState,  Component } from 'react'
import { tabelPenyerapan } from './Api/Deis'
import { Link } from 'react-router-dom'
import { milyard, ColoringDeviasiTarget, kabisat, listBulan, subdomain, lastMonthDate, exportExcel } from './Api/Value'
import TitleBar from './Components/Menu/TitleBar'
import { Text } from './Components/Form/Input'

import { Button, SwitchButton } from './Components/Form/Button'
import { Excel } from './Components/Icons/Apps'
import { NavLink } from 'react-router-dom'

import { getAllSatker } from './Api/Satker'
import axios from 'axios'
const yearKabisat = kabisat();
const ymd = lastMonthDate()
const columnExcel = [
                { key: "namasatker", "column": "Satker"  },
                { key: "pagu", "column": "Pagu"  },
                { key: "target", "column": "Target"  },
                { key: "deviasi", "column": "Deviasi"  },
                { key: "sp2d", "column": "SP2D"  },
                { key: "sp2d_percent", "column": "Percent_SP2D"  },
                { key: "deviasi_sp2d_rupiah", "column": "Deviasi_SP2D_Rupiah"  },
                { key: "deviasi_sp2d_target", "column": "Deviasi_SP2D_Thd_Target"  },
                { key: "deviasi_sp2d_pagu", "column": "Deviasi_SP2D_Thd_Pagu"  }
            ] 

let emptyObj  = {}
const now = new Date()
const bulanSekarang = listBulan[now.getMonth()]
const defaultMinggu = kabisat() === false && now.getMonth() === 1 ? 4 : 5
const initParam = "mingguKe=" + ( bulanSekarang + "_" + defaultMinggu).toLowerCase()
const skip = ["deviasi", "deviasi_sp2d_target", "deviasi_sp2d_pagu", "deviasi_spm_target", "deviasi_spm_pagu","deviasi_keuangan_target", "deviasi_keuangan_pagu", "sp2d_percent"]
let rawResponse = []
let source      = null
class App extends Component{
    state = {
        satkerList: [],
        queryUrl: [initParam, ("tgl_sp2d=" + ymd)],
        objTotal: {}
    }
    componentDidMount = () => {
        this.load()
    }
    load = async () => {
        const g = await getAllSatker()
        if(g.code === 200){
            const data = g.res.data 
            this.setState({
                satkerList: data.map((x) => ({ ...x, ready: false }))
            }, () => {
                this.getData(this.state.queryUrl.join("&"))
            })
        }
    }
    setLazyLoad = (res, a) => {
        const satkerList = this.state.satkerList 
        if(res.code === 200){
            rawResponse.push({ ...a, ...res.res.data, ready: true })
        }else{
            rawResponse.push({ ...a, error:false, ready: undefined})
        }

        if(satkerList.length === rawResponse.length){
            let total = {}
            let satkerList  = this.state.satkerList.map((x) => {
                const cari = rawResponse.filter((r) => r.sik_url === x.sik_url)
                let result = null 
                if(cari.length > 0){
                    result = cari[0]
                }else{
                    result = {...x, ready: undefined}
                }
                if(result["sp2d"] !== undefined){
                    result["sp2d"] = parseFloat(result.sp2d)
                }
                if(result["pagu"] !== undefined){
                    result["pagu"] = parseFloat(result.pagu)
                }
                if(result["target"] !== undefined){
                    result["target"] = parseFloat(result.target)
                }
                for(let p of skip){
                    if(result[p] !== undefined){
                        result[p]  = parseFloat(result[p].toFixed(2))
                    }
                }
                return result 
            })

            for(let t of satkerList){
                total = this.sumData(t)
            }

            this.setState({
                satkerList: satkerList,
                objTotal: total
            })
        }
    }
    getData = (param="") => {
        
        emptyObj = {}
        rawResponse = []
        if(source){
            source.cancel()
        }
        source     = axios.CancelToken.source()
        let config = { cancelToken: source.token}
        const satkerList = this.state.satkerList
        
        for(let a of satkerList){
            const z = tabelPenyerapan({ target: a.sik_url, param: param, config: config, apiKey: a.sik_apikey  }).then((res) => this.setLazyLoad(res, a))
     
        }
    }
    toExcel = () => {
        let satkerList = this.state.satkerList
        satkerList.push(this.state.objTotal)
        exportExcel({object: satkerList, title: "Tabel Penyerapan", pattern: columnExcel})
    }
    handleQuery = (param, code, month=false) => {
        let satkerList = this.state.satkerList.map((x) => {
            
            return {...x, ready: false}
        })
        this.setState({
            satkerList: satkerList
        })
        // code 0 = renkas kode
        // code 1 = tgl_Sp2d
        let newQueryUrl = this.state.queryUrl 
        if(code == 0 && month !== false){
            const m     = listBulan.indexOf(month)
            const lateDate = lastMonthDate(m)
            newQueryUrl[1] = "tgl_sp2d=" + lateDate
            const sp2d_date = document.getElementById("sp2d_date")
            if(sp2d_date){
                sp2d_date.value = lateDate
            }
            newQueryUrl[code] = param
        }else{
            newQueryUrl[code] = param
        }
        this.setState({
            queryUrl: newQueryUrl,
            objTotal: {}
        })
        
        let buildQuery = newQueryUrl.filter(x => x!== "").join("&")
        this.getData(buildQuery)
    }
    handleDateSp2d = (evt) => {
        const value = evt.target.value 
        this.handleQuery(`tgl_sp2d=${value}`, 1)
    }
    sumData = (hasil) => {

        const keys = Object.getOwnPropertyNames(hasil)
        let newObj = emptyObj 
        
        for(let k of keys){
            // percent perhitungan rumus diskip
            if(skip.indexOf(k) >= 0){
                continue;
            }
            const num = parseFloat(hasil[k])
            // prevent Not A Number to increment
            if(Number.isNaN(num) !== true){
                if(newObj[k] === undefined){
                    newObj[k] = num
                }else{
                    newObj[k] += num
                }
            }
        }
        const type = ["sp2d", "spm", "keuangan"]
        // perhitungan percent deviasi
        newObj["deviasi"]      = parseFloat(((newObj["target"] / newObj["pagu"])*100).toFixed(2))
        newObj["sp2d_percent"] = parseFloat(((newObj["sp2d"] / newObj["target"]) * 100).toFixed(2))
        // perhitungan tabs 3 THD target dan pagu
        for(let t of type){
            // thd target
            newObj[`deviasi_${t}_target`] = parseFloat((((newObj[t] - newObj['target']) / newObj['target']) * 100).toFixed(2))
            // thd pagu
            newObj[`deviasi_${t}_pagu`]   = ((newObj[`deviasi_${t}_rupiah`] / newObj['pagu'])*100)
        }
        emptyObj = newObj 
        return emptyObj
    }
    render(){
        const objTotal = this.state.objTotal
        return (
            <div>
                <div className="mb-2">
                    <TitleBar 
                        routing = {[
                            {
                                route: subdomain + "/dashboard",
                                name: "Dashboard"
                            }
                        ]}
                        title   = "Tabel Penyerapan"
                    >
                    <div className="pt-[5px] pr-[8px]">
                        <Button onClick={ this.toExcel } className="text-sm bg-green-500 dark:bg-green-700 flex px-3 gap-1 py-2">
                            <Excel className="w-5 h-5 fill-slate-50"/>
                            <div className="text-slate-50 font-medium">
                                Excel
                            </div>
                        </Button>
                    </div>
                    </TitleBar>
                </div>
                <div className="bg-white dark:bg-slate-800 px-4 py-5 rounded-md">   
                <div className="overflow-x-auto ">
                    <div className="w-[1200px] xl:w-full pb-10">
                        <table className="w-full text-sm">
                            <thead >
                                <tr>
                                    <td rowSpan="3" className="bg-slate-600 dark:bg-slate-700 rounded-l-md text-white text-center border-r-[1px] border-r-white dark:border-r-slate-800 w-14">
                                        No
                                    </td>
                                    <td  rowSpan="3" className="bg-slate-600 dark:bg-slate-700 text-center text-white border-r-[1px] border-r-white dark:border-r-slate-800 w-36">
                                        Satker
                                    </td>
                                    <td rowSpan="3" className="bg-orange-600 dark:bg-orange-800 text-center text-white border-r-[1px] border-b-[1px] border-r-white dark:border-r-slate-800 dark:border-b-slate-800 w-16">
                                        Pagu
                                    </td>
                                    <td className="bg-purple-600 dark:bg-purple-800 text-center text-white border-[1px] border-white dark:border-slate-800 py-2" colSpan="2">
                                        Target
                                        <DropdownMinggu 
                                            result = { this.handleQuery }
                                        />
                                    </td>
                                    <td className="bg-green-700 dark:bg-green-800 text-center text-white border-[1px] border-r-white dark:border-slate-800" colSpan="5">
                                        Filter SP2D
                                        <div className="px-1 pt-1">
                                            <input type="date" onChange={ this.handleDateSp2d } defaultValue={ ymd } id="sp2d_date" className="text-slate-700 rounded-md border-none text-sm bg-teal-100 dark:bg-teal-950 dark:text-slate-50" />
                                        </div>
                                    </td>
                                    <td className="bg-green-500 dark:bg-green-700 text-center rounded-r-md text-white border-[1px] border-white dark:border-slate-800" colSpan="8">
                                        Realisasi & Deviasi Kumulatif
                                    </td>
                                </tr>
                                <tr>
                                    <td className="bg-purple-600 dark:bg-purple-800 text-center text-white  border-[1px] border-white w-[50px] dark:border-slate-800" rowSpan="2">
                                        Rp. M
                                    </td>
                                    <td className="bg-purple-600 dark:bg-purple-800 text-center text-white  border-[1px] border-white w-[50px] dark:border-slate-800 p-1" rowSpan="2">
                                        %
                                    </td>
                                    <td className="bg-green-700 py-2 dark:bg-green-800 text-center text-white  border-[1px] border-white dark:border-slate-800 " colSpan="2">
                                        SP2D
                                    </td>
                                    <td className="bg-green-700 dark:bg-green-800 text-center text-white  border-[1px] border-white dark:border-slate-800" colSpan="3">
                                        Deviasi
                                    </td>

                                    { /* BATAS */ }
                            
                                    <td className="bg-emerald-600 dark:bg-emerald-700 text-center text-white  border-[1px] border-white dark:border-slate-800" >
                                        SPM
                                    </td>
                                    <td className="bg-emerald-600 dark:bg-emerald-700 text-center text-white  border-[1px] border-white dark:border-slate-800" colSpan="3">
                                        Deviasi
                                    </td>

                                    { /* BATAS */ }
                                
                                    <td className="bg-teal-600 dark:bg-teal-700 text-center text-white  border-[1px] border-white dark:border-slate-800" >
                                        SIK
                                    </td>
                                    <td className="bg-teal-600 dark:bg-teal-700 text-center text-white  border-[1px] border-white dark:border-slate-800" colSpan="3">
                                        Deviasi
                                    </td>

                                </tr>
                                <tr>
                                    <td className="bg-green-700 dark:bg-green-800 text-center text-white  border-[1px] border-white dark:border-slate-800 w-14" valing="middle">
                                        Rp. M
                                    </td>
                                    <td className="bg-green-700 dark:bg-green-800 text-center text-white  border-[1px] border-white dark:border-slate-800 px-1 w-14" >
                                        %
                                    </td>

                                    <td className="bg-green-700 dark:bg-green-800 text-center text-white  border-[1px] border-white dark:border-slate-800 w-14" >
                                        Rp. M
                                    </td>
                                    <td className="bg-green-700 dark:bg-green-800 text-center text-white  border-[1px] border-white dark:border-slate-800 pt-3 pb-3 w-16" >
                                        (%) <br/> Thd Target
                                    </td>
                                    <td className="bg-green-700 dark:bg-green-800 text-center text-white  border-[1px] border-white dark:border-slate-800 w-16" >
                                        (%) <br/> Thd Pagu
                                    </td>
                                    { /* BATAS */ }
                                    <td className="bg-emerald-600 dark:bg-emerald-700 text-center text-white  border-[1px] border-white dark:border-slate-800 w-16" valing="middle">
                                        Rp. M
                                    </td>
                            

                                <td className="bg-emerald-600 dark:bg-emerald-700 text-center text-white  border-[1px] border-white dark:border-slate-800 w-14" >
                                    Rp. M
                                </td>
                                <td className="bg-emerald-600 dark:bg-emerald-700 text-center text-white  border-[1px] border-white dark:border-slate-800 pt-3 pb-3 w-16" >
                                    (%) <br/> Thd Target
                                </td>
                                <td className="bg-emerald-600 dark:bg-emerald-700 text-center text-white  border-[1px] border-white dark:border-slate-800 w-16" >
                                    (%) <br/> Thd Pagu
                                </td>
                                { /* BATAS */ }
                                <td className="bg-teal-600 dark:bg-teal-700 text-center text-white  border-[1px] border-white dark:border-slate-800 w-14" valing="middle">
                                    Rp. M
                                </td>
                                <td className="bg-teal-600 dark:bg-teal-700 text-center text-white  border-[1px] border-white dark:border-slate-800 w-14" >
                                    Rp. M
                                </td>
                                <td className="bg-teal-600 dark:bg-teal-700 text-center text-white  border-[1px] border-white dark:border-slate-800 pt-3 pb-3 w-16" >
                                    (%) <br/> Thd Target
                                </td>
                                    <td className="bg-teal-600 dark:bg-teal-700 text-center text-white  border-[1px] border-white dark:border-slate-800 w-16" >
                                        (%) <br/> Thd Pagu
                                    </td>
                                    { /* BATAS */ }
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    this.state.satkerList.map((x, i) => {
                                        return (
                                            <Row 
                                                index={ i }
                                                nomor = { x.ready === false ? <Spin /> : (x.ready ===  undefined) ? <IconError /> : i+1 }
                                                data  = { x }
                                                key   = {i}
                                                title = { x.namasatker }
                                                queryUrl={ this.state.queryUrl }
                                            />
                                        )
                                    })
                                }
                            </tbody>
                            <tfoot>
                                <tr className="border-t-[1px] border-slate-50 dark:border-slate-800">
                                    <Col val="#" className="text-center bg-slate-700 text-slate-50"/>
                                    <Col val="Total" className="text-center bg-slate-700 text-slate-50"/>
                                    <Col val={ milyard(objTotal.pagu) } className="text-right bg-orange-600 dark:bg-orange-800 text-slate-50" />
                                    <Col val={ milyard(objTotal.target) } className="text-right bg-purple-700 dark:bg-purple-800 text-slate-50" />
                                    <Col val={ (objTotal.deviasi) ? objTotal.deviasi.toFixed(2) : null } className="text-right bg-purple-700 dark:bg-purple-800 text-slate-50" />
                                    <Col val={ milyard(objTotal.sp2d) } className="text-right bg-green-700 text-slate-50 dark:bg-green-800" />
                                    <Col val={ (objTotal.sp2d_percent) ? parseFloat(objTotal.sp2d_percent).toFixed(2) : null } className="text-right dark:bg-green-800 bg-green-700 text-slate-50" />
                                    <Col val={ milyard(objTotal.deviasi_sp2d_rupiah) } className="text-right dark:bg-green-800 bg-green-700 text-slate-50" />
                                    <Col val={ (objTotal.deviasi_sp2d_target) ? objTotal.deviasi_sp2d_target.toFixed(2) : null } className={`text-right ${ColoringDeviasiTarget(objTotal.deviasi_sp2d_target)}`} />
                                    <Col val={ (objTotal.deviasi_sp2d_pagu) ? objTotal.deviasi_sp2d_pagu.toFixed(2) : null } className="text-right bg-green-700 text-slate-50 dark:bg-green-800" />
                                    <Col val={ milyard(objTotal.spm) } className="text-right bg-emerald-600 dark:bg-emerald-800 text-slate-50" />
                                
                                    <Col val={ milyard(objTotal.deviasi_spm_rupiah) } className="text-right bg-emerald-600 dark:bg-emerald-800 text-slate-50"/>
                                    <Col val={ (objTotal.deviasi_spm_target) ? objTotal.deviasi_spm_target.toFixed(2) : null } className={`text-right ${ColoringDeviasiTarget(objTotal.deviasi_spm_target)}`}/>
                                    <Col val={ (objTotal.deviasi_spm_pagu) ? objTotal.deviasi_spm_pagu.toFixed(2) : null } className={`text-right bg-emerald-600 dark:bg-emerald-800 text-slate-50`}/>
                                    <Col val={ milyard(objTotal.keuangan) } className={`text-right bg-teal-600 dark:bg-teal-700 text-slate-50`}/>
                                
                                    <Col val={ milyard(objTotal.deviasi_keuangan_rupiah) } className="text-right bg-teal-600 dark:bg-teal-700 text-slate-50"/>
                                    <Col val={ (objTotal.deviasi_keuangan_target) ? objTotal.deviasi_keuangan_target.toFixed(2) : null } className={`text-right ${ColoringDeviasiTarget(objTotal.deviasi_keuangan_target)}`}/>
                                    <Col val={ (objTotal.deviasi_keuangan_pagu) ? objTotal.deviasi_keuangan_pagu.toFixed(2) : null } className={`text-right bg-teal-600 dark:bg-teal-700 text-slate-50`}/>
                                </tr>
                            </tfoot>
                       </table>
                    </div>
                </div>
            </div>
        </div>
        )
    }
}

const IconError = () => <span className="text-red-600 text-md font-bold">!</span>
const Row = ({ nomor, title, index, data, queryUrl }) => {
    const val = (data) ? data : {}
    return (
        <tr className={ `${index%2 === 0 ? "bg-slate-100 dark:bg-slate-900" : "bg-white dark:bg-slate-800"} ` }>
            <Col val={nomor} className="text-center "/>
            <Col val={title}/>
            <Col val={ milyard(val.pagu) } className="text-right bg-orange-600 dark:bg-orange-800 text-slate-50" />
            <Col val={ milyard(val.target) } className="text-right bg-purple-600 dark:bg-purple-800 text-slate-50" />
            <Col val={ (val.deviasi) ? val.deviasi.toFixed(2) : null } className="text-right bg-purple-600 dark:bg-purple-800 text-slate-50" />
            <Col to={ `${subdomain}/realisasiPerKonkin/sp2d/${queryUrl[1]}/${queryUrl[0]}/${btoa(title)}` } color="bg-green-700 dark:bg-green-800 text-slate-50" val={ 
                        milyard(val.sp2d)
                    } className="cursor-pointer hover:opacity-75 text-right bg-green-700 dark:bg-green-800 text-slate-50" />
            <Col val={ (val.sp2d_percent) ? parseFloat(val.sp2d_percent).toFixed(2) : 0 } className="text-right text-slate-50 dark:bg-green-800 bg-green-700" />
            <Col to={ `${subdomain}/listTransaksi/sp2d/${btoa(title)}/${queryUrl[0]}/${queryUrl[1]}` } color="dark:bg-green-800 bg-green-700 text-slate-50" val={ 
                
                    milyard(val.deviasi_sp2d_rupiah) 
                
            } className={`text-right cursor-pointer hover:opacity-75 dark:bg-green-800 bg-green-700 text-slate-50`} />
            <Col val={ (val.deviasi_sp2d_target) ? val.deviasi_sp2d_target.toFixed(2) : null } className={`text-right ${ColoringDeviasiTarget(val.deviasi_sp2d_target)}`} />
            <Col val={ (val.deviasi_sp2d_pagu) ? val.deviasi_sp2d_pagu.toFixed(2) : null } className="text-right dark:bg-green-800 bg-green-700 text-slate-50" />
            <Col to={`${subdomain}/realisasiPerKonkin/spm/${queryUrl[1]}/${queryUrl[0]}/${btoa(title)}`} val={ 
                milyard(val.spm)
            } className="text-right cursor-pointer hover:opacity-75 text-slate-50 bg-emerald-600 dark:bg-emerald-700" />
          
            <Col to={`${subdomain}/listTransaksi/spm/${btoa(title)}/${queryUrl[0]}/${queryUrl[1]}`} color="bg-emerald-500 dark:bg-emerald-700 text-slate-50" val={           
                milyard(val.deviasi_spm_rupiah)
            } className="text-right cursor-pointer hover:opacity-75 bg-emerald-600 dark:bg-emerald-700 text-slate-50"/>
            <Col val={ (val.deviasi_spm_target) ? val.deviasi_spm_target.toFixed(2) : null } className={`text-right ${ColoringDeviasiTarget(val.deviasi_spm_target)}`}/>
            <Col val={ (val.deviasi_spm_pagu) ? val.deviasi_spm_pagu.toFixed(2) : null } className={`text-right bg-emerald-600 dark:bg-emerald-700 text-slate-50`}/>
            <Col to={`${subdomain}/realisasiPerKonkin/sik/${queryUrl[1]}/${queryUrl[0]}/${btoa(title)}`} color="bg-emerald-600 dark:bg-emerald-700 text-slate-50" val={ 
                milyard(val.keuangan) 
            } className={`text-right cursor-pointer hover:opacity-75 bg-teal-600 text-slate-50 dark:bg-teal-700`}/>
            <Col to={`${subdomain}/listTransaksi/sik/${btoa(title)}/${queryUrl[0]}/${queryUrl[1]}`} color="bg-teal-600 dark:bg-teal-700 text-slate-50" val={ 
                milyard(val.deviasi_keuangan_rupiah)
            } className="text-right cursor-pointer hover:opacity-75 bg-teal-600 dark:bg-teal-700 text-slate-50"/>
            <Col val={ (val.deviasi_keuangan_target) ? val.deviasi_keuangan_target.toFixed(2) : null } className={`text-right ${ColoringDeviasiTarget(val.deviasi_keuangan_target)}`}/>
            <Col val={ (val.deviasi_keuangan_pagu) ? val.deviasi_keuangan_pagu.toFixed(2) : null } className={`text-right bg-teal-600 text-slate-50 dark:bg-teal-700`}/>
        </tr>
    )
}
const Col = ({ val, className, to=null, color=""}) => {
    if(to === null){
        return (
            <td className={` py-3 px-1 border-slate-200  dark:border-slate-900  dark:text-slate-50 ${ className }`}>
                { val }
            </td>
        )
    }else if(to !== null){
        return (
            <td className={`p-0  dark:border-slate-900 ${color}`}>
                <NavLink to={ to }>
                    <div className={` py-3 px-1  dark:text-slate-50 ${ className }`}>{val}</div>
                </NavLink>
            </td>
        )
    }
}
const Spin = () => {
    return (
        <div role="status">
            <svg style={{margin: "auto", background: "none", display: "block", shapeRendering: "auto"}} width="20px" height="20px" viewBox="0 0 100 100" preserveAspectRatio="xMidYMid">
                <circle cx="50" cy="50" fill="none" stroke="#2563eb" strokeWidth="10" r="35" strokeDasharray="164.93361431346415 56.97787143782138">
                <animateTransform attributeName="transform" type="rotate" repeatCount="indefinite" dur="1s" values="0 50 50;360 50 50" keyTimes="0;1"></animateTransform>
                </circle>
            </svg>
        </div>
    )
}
const KolomToggle = ({ title, enabled, setEnabled }) => {
    return (
        <div className="flex gap-2">
            <div className="text-sm w-14">
                { title }
            </div>
            <div>
                <SwitchButton 
                    enabled={ enabled }
                    setEnabled = { setEnabled }
                />
            </div>
        </div>
    )
}
const DropdownMinggu = ({ result }) => {

    const [ bulanSelected, setBulanSelected ] = useState(bulanSekarang)
    const [ mingguKe, setMingguKe ] = useState(defaultMinggu)

    const handleBulan = (ev) => {
        const value     = ev.target.value
        setBulanSelected(value)
        const safeKabisat = (parseInt(mingguKe) === 5 && value === "Feb" && yearKabisat === false) ? 4 : 5 
        if(safeKabisat === 4){
            setMingguKe(4)
        }else{
            setMingguKe(5)
        }
        result(`mingguKe=${value.toLowerCase()}_${safeKabisat}`, 0, value)
    }
    const handleMinggu = (ev) => {
        const value = ev.target.value
        result(`mingguKe=${bulanSelected.toLowerCase()}_${value}`, 0, bulanSelected)
        setMingguKe(value)
    }
    return (
        <div>
            <div className="px-1 pt-1">
                <select onChange={ handleBulan } defaultValue={ bulanSekarang } className="w-full p-0 py-[2px] pl-2 text-slate-700 rounded-md border-none text-sm bg-purple-100 dark:bg-purple-950 dark:text-slate-50">
                    {
                        listBulan.map((m, k) => {
                            return <option key={ k } value={ m }>{ m }</option>
                        })
                    }
                </select>
            </div>
            <div className="px-1 pt-1">
                <select onChange={ handleMinggu } value={ mingguKe } className="w-full p-0 py-[2px] pl-2 text-slate-700 rounded-md border-none text-sm bg-purple-100 dark:bg-purple-950 dark:text-slate-50">
                    <option value="1">I</option>
                    <option value="2">II</option>
                    <option value="3">III</option>
                    <option value="4">IV</option>
                    {
                        bulanSelected === "Feb" && yearKabisat === false ?
                            null
                        :
                            <option value="5">V</option>
                    }
                </select>
            </div>
            
        </div>
        
    )
}
export default App