
import { Component, useRef, useEffect } from 'react'
import { SwitchButton } from '../Form/Button'
import { SelectIcon } from '../Form/Input'
import { RectangleStackIcon } from '@heroicons/react/24/outline'
import { getAllSatker } from '../../Api/Satker'
import { grafikBar, listKonkin } from '../../Api/Deis'
import { milyard } from "../../Api/Value";
import Bar from './Rechart/Bar'
import axios from 'axios'
let rawResponse = []
let source      = null
let rawKonkin   = []
let counterKonkin= 0
class BarChartPenyerapan extends Component{
    constructor(props){
        super(props)
        this.state  = {
            satker: {
            },
            konkin: [],
            data_2: "",
            dataBar: []
        }
    }
    componentDidMount = () => {
        this.onLoad();
    }
    onLoad = async () => {
    
        const r = await getAllSatker()
        if(r.code === 200){
            let satker = {}
            
            for(let a of r.res.data){
                satker[`_${a.kodesatker}`] = { namasatker: a.namasatker,checked: false, kodesatker: a.kodesatker, url: a.sik_url, api_key: a.sik_apikey }
            }
            this.setState({
                satker: satker
            }, () => {
               this.getBar()
            })
        }
    }
    getBar = () => {
        rawResponse = []
        if(source){
            source.cancel()
        }
        source = axios.CancelToken.source()
        let config = { cancelToken: source.token}
        const satker = this.state.satker
      
        const data_2 = this.state.data_2
        const konkin = this.state.konkin.filter((x) => x.checked).map((x) => {
            return x.kk
        })
        const max = Object.getOwnPropertyNames(satker)
        // cek apakah ada yang di checked
        let cek   = false
        let maximumReq = 0
        for(let c of max){
            if(satker[c].checked === true){
                cek = true
                maximumReq++
            }
        }
        for(let a of max){
            if(cek === false){
                grafikBar({ config: config, target: satker[a]["url"], apikey: satker[a]["api_key"], param: `konkin=${btoa(JSON.stringify(konkin))}&sumber_dana=${data_2}` })
                .then((res) => this.responseGrafik(res, max.length))
            }else if(cek === true && satker[a].checked === true){
                grafikBar({ config: config, target: satker[a]["url"], apikey: satker[a]["api_key"], param: `konkin=${btoa(JSON.stringify(konkin))}&sumber_dana=${data_2}` })
                .then((res) => this.responseGrafik(res, maximumReq))
            }
        }
   
    }
    responseGrafik = (res, panjangRequest) => {
        if(res.code === 200){
            rawResponse.push(JSON.stringify(res.res.data))
            if(rawResponse.length === panjangRequest){
                this.refreshBar()
            }
        }else{
            rawResponse.push(false)
            if(rawResponse.length === panjangRequest){
                this.refreshBar()
            }
        }
    }
    refreshBar = () => {
        let theData = { name: "", Realisasi: 0, Pagu: 0 }
        for(let p of rawResponse){
            if(p){
                const el = JSON.parse(p)
                theData["Realisasi"] += el.realisasi 
                theData["Pagu"]      += el.pagu  
            }
        }
        
        theData["Realisasi"]   = parseFloat(milyard(theData["Realisasi"]))
        theData["Pagu"]        = parseFloat(milyard(theData["Pagu"]))

        this.setState({
            dataBar: [theData]
        })
    }
    cariKonkin = () => {
        rawKonkin =  []
        counterKonkin = 0
        let satker = this.state.satker 
        let own    = Object.getOwnPropertyNames(satker)
        let max    = 0 
        for(let k of own){
            const val = satker[k]
            if(val.checked){
                max++
            }
        }
        for(let o of own){
            const val = satker[o]
            if(val.checked){
                listKonkin({ target: val.url, apikey: val.api_key }).then((r) => this.responseKonkin(r, val.url, max))
            }
        }
    }
    handleToggle = (key) => {
        let satker = this.state.satker 
        satker[key]["checked"] = !satker[key]["checked"] 
        this.setState({ satker: satker, konkin: [] }, () => {
            this.cariKonkin()
            this.getBar()
        })
    }
    handleKonkin = (kk) => {
        let konkin = this.state.konkin 
        let index = 0 
        for(let k of konkin){
            if(k.kk == kk){
                konkin[index]["checked"] = (konkin[index]["checked"]) ? false : true
            }
            index++
        }
        this.setState({
            konkin: konkin
        }, () => {
            this.getBar()
        })
    }
    responseKonkin = (res, url, max) => {
        
        if(res.code == 200){
            let kk = res.res.data.map((x) => ({ kk: x, url: url,  checked: false }))
            for(let k of kk){
                rawKonkin.push(k)
            }
            counterKonkin++
            if(counterKonkin === max){
                this.setState({
                    konkin: rawKonkin
                })
            }
        }
    }
    handleData = (type, target) => {
       
            if (target === this.state.data_2) {
                
            }else{
                this.setState({ data_2: target }, () => this.getBar())
            }
        
    }
    render(){
   
        const satker = this.state.satker
        
        return (
            <div className="md:flex gap-2">
                <div className="xl:w-[30%] xl:grid xl:grid-cols-2 gap-2 pt-2">
                    <div className="mb-2 xl:mb-0">
                        <div className="bg-white/60 dark:bg-black/50 dark:text-slate-50 rounded-t-md px-3 py-2 text-center text-sm">
                            Satker                    
                        </div>
                        <div className="bg-white dark:bg-slate-800 p-3 rounded-b-md h-96 pl-4">
                            {
                                Object.getOwnPropertyNames(satker).map((x) => {
                                    return (
                                        <Slider key={x} title={ satker[x]["namasatker"] } enabled={ satker[x]["checked"] } setEnabled={ () => this.handleToggle(x) } />
                                    )
                                })
                            }   
                        </div>
                    </div>

                    <div className="mb-2 xl:mb-0 ">
                        <div className="bg-white/60 dark:bg-black/50 dark:text-slate-50 rounded-t-md px-3 py-2 text-center text-sm">
                            Konkin                    
                        </div>
                        <div className="bg-white dark:bg-slate-800 p-3 rounded-b-md ">
                        <div className="h-[360px] overflow-auto ">
                        {
                            this.state.konkin.length === 0 ?
                                <div className="text-sm italic dark:text-slate-50 text-slate-600 text-center">
                                    Select Satker                       
                                </div>
                            :
                                this.state.konkin.map((x) => {
        
                                    return (
                                        <Slider key={x.kk} title={ x.kk } enabled={ x.checked } setEnabled={ () => this.handleKonkin(x.kk) } />
                                    )
                                })
                        }
                        </div>
                        
                    </div>
                    </div>
                
                    
                    <div className="mb-2 xl:mb-0">
                        <div className="bg-white/60  dark:bg-black/50 dark:text-slate-50 rounded-t-md px-3 py-2 text-center text-sm">
                            Data                    
                        </div>
                        <div className="bg-white p-3 dark:bg-slate-800 rounded-b-md h-24">
                            <FormRadio 
                                data = { this.state.data_2 }
                                handleData = { this.handleData }
                            />
                        </div>
                    </div>
                  
                </div>


                <div className="mt-2  w-[70%]">
                    <div className="bg-white dark:bg-slate-800 rounded-md p-3">
                       <Bar 
                        data = { this.state.dataBar }
                        isDark = { this.props.isDark }
                       />
                    </div>
                </div>
            </div>
            
        )
    }
}

const App = ({ isDark }) => {
    
    return <BarChartPenyerapan isDark={isDark}/>
}
const SatkerList = ({ title, enabled, setEnabled }) => {
    return (
        <div className="flex text-sm gap-2 mb-1">
            <div className="w-[46px]">
                <SwitchButton enabled={ enabled } setEnabled={ setEnabled }  />        
            </div>
            <div className="w-full">
                { title }
            </div>
        </div>
    )
}
const Slider = ({ title, enabled, setEnabled }) => {
    return (
        <div className="flex text-sm gap-2 mb-1">
            <div className="w-[46px]">
                <SwitchButton enabled={ enabled } setEnabled={ setEnabled }  />        
            </div>
            <div className="w-full">
                { title }
            </div>
        </div>
    )
}

const FormRadio = ({ handleData, data }) => {
    
    return (
        <div>
            <div className="flex text-sm gap-2 mb-1 cursor-pointer" onClick={ () => handleData("data_2", "") }>
                <div className={`w-4 h-4 rounded-full ${ data == "" ? "bg-blue-500 border-blue-500 dark:bg-blue-600 dark:border-blue-600" : "bg-slate-200 border-slate-300 dark:bg-slate-700 dark:border-slate-600" } border-[1px] `}></div>
                <div className="text-slate-800 dark:text-slate-50">All</div>
            </div>

            <div className="flex text-sm gap-2 mb-1 cursor-pointer" onClick={ () => handleData("data_2", "RM") }>
                <div className={`w-4 h-4 rounded-full ${ data == "RM" ? "bg-blue-500 border-blue-500 dark:bg-blue-600 dark:border-blue-600" : "bg-slate-200 border-slate-300 dark:bg-slate-700 dark:border-slate-600" } border-[1px] `}></div>
                <div className="text-slate-800 dark:text-slate-50">RM</div>
            </div>

            <div className="flex text-sm gap-2 cursor-pointer" onClick={ () => handleData("data_2", "BLU") }>
            <div className={`w-4 h-4 rounded-full ${ data == "BLU" ? "bg-blue-500 border-blue-500 dark:bg-blue-600 dark:border-blue-600" : "bg-slate-200 border-slate-300 dark:bg-slate-700 dark:border-slate-600" } border-[1px] `}></div>
                <div className="text-slate-800 dark:text-slate-50">BLU</div>
            </div>
        </div>
        
    )
}
export default App