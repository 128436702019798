import { XCircleIcon } from '@heroicons/react/24/solid'

export const Danger = ({ message }) => {
    return (
        <div className={`${message === "" ? "hidden" : ""} transition-all duration-200 w-full dark:bg-rose-600 bg-rose-100 p-2 pl-4 rounded-md`}>
            <div className="w-full flex">
                <XCircleIcon  className="w-6 text-rose-600 dark:text-slate-50"/>
                <span className="text-rose-700 pl-3 pt-[2px] text-sm dark:text-slate-50">
                    <span className="font-bold text-rose-500 dark:text-slate-50">Peringatan!</span>
                    <br/> { message }
                </span>
            </div>
            
        </div>
    )
}