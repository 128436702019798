import { Fragment, useRef, useEffect, useState } from 'react'
import { MenuIcons, subdomain } from '../../Api/Value'
import { Popover, Transition, Menu  } from "@headlessui/react"
import { NavLink, useLocation, useNavigate  } from "react-router-dom";


const MainMenu = ({list, expand}) => {
    const location = useLocation()
 
    return (
        <Fragment>
            { 
                list.map((v, k) => {
                    const isChild = v.childs.length > 0
                    const theIcon = MenuIcons(v.route)

                    return isChild ? 
                    (
                        <BridgeChild 
                          key = { k }
                          v = { v }
                          expand ={ expand }
                          path   = { location.pathname } 
                          theIcon ={ theIcon }
                        />
                       
                    )
                    : 
                    (
                        <NavLink to={ v.route } key={ k }> 
                        {({ isActive, isPending }) => (
                          <div className={`h-[34px] w-[34px] ${ isActive ? "bg-blue-700 dark:hover:bg-blue-600 shadow-xl text-white" : "dark:hover:bg-slate-800 hover:bg-slate-50/40 dark:text-slate-50 text-slate-700" } ${ expand ? "w-full" : "w-9"} text-sm flex gap-2 p-[4px]  rounded-lg  mb-2`}>
                          <div className="">
                            { theIcon }                        
                          </div>
                           {
                            expand ?
                                <div  className={`pt-[4px] transition ease-in delay-150 `}>
                                  <span className={ `${ isActive ? "text-white" : "text-slate-900 dark:text-slate-50" }`}>{ v.name }</span>
                                </div>
                            :
                                null
                           }
                          </div>
                          )}
                        </NavLink>        
                    )
                })
            }
        </Fragment>
    )
}

const BridgeChild = ({ v, theIcon, expand, path }) => {

  const active = (subdomain === path || path === "/app/") ? false : JSON.stringify(v.childs).indexOf(path) >= 0
  
  return (
    <div className="mb-1">
        <ChildMenu v={ v } icon={ theIcon } expand={ expand } name={ v.name } active={  active } />
    </div>
  )
}

export const ChildMenu = ({ icon, children, name, expand, active, v }) => {
    const [ isOpen, setIsOpen ] = useState(false)
    const navigate = useNavigate();
    const location = useLocation()

    const buttonRef = useRef(null)
    const timeoutDuration = 200
    let timeout
    
    const closePopover = () => {
        return buttonRef.current?.dispatchEvent(
          new KeyboardEvent("keydown", {
            key: "Escape",
            bubbles: true,
            cancelable: true
          })
        )
      }
    
      const onMouseEnter = (open) => {
        setIsOpen(true)
        clearTimeout(timeout)
        if (open) return
        return buttonRef.current?.click()
      }
    
      const onMouseLeave = (open) => {
        setIsOpen(false)
        if (!open) return
        timeout = setTimeout(() => closePopover(), timeoutDuration)
      }

      useEffect(() => {
        if(buttonRef){
          const isMe = v.childs.filter((x) => x.route === location.pathname)
        
          if(isMe.length > 0 && isOpen === true){
            buttonRef.current?.click()          
          }
        }
      }, [location]);
      return (
        <Popover className="relative border-none" style={{ border: 'none' }}>
        {({ open }) => {
          return (
            <>
              <div onMouseLeave={onMouseLeave.bind(null, open)}>
                <Popover.Button
                  ref={buttonRef}
                  className={`
                  ${open ? "" : "text-opacity-90"}
                  cursor-pointer hover:text-opacity-100 focus:outline-none focus-visible:ring-0 hover:ring-0 ring-0 border-none active:border-none active:ring-0
                  ${ active ? "hover:bg-blue-600/40 dark:hover:bg-blue-600 text-slate-50 bg-blue-600 shadow-lg dark:text-slate-50" : "hover:bg-white/40 dark:hover:bg-slate-800 text-slate-700 dark:text-slate-50" }
                  h-[34px] ${ expand ? "w-full" : "w-[34px]"} text-sm flex gap-2 p-[4px] rounded-lg `}
                  onMouseEnter={onMouseEnter.bind(null, open)}
                  onMouseLeave={onMouseLeave.bind(null, open)}
                  style={{ border: 'none' }}
                >
                 { icon } 
                 {
                    expand ?
                    <div className={`${active ? "text-slate-50" : "text-slate-900"} pt-[3px] dark:text-slate-50 `}>
                        { name } 
                    </div> 
                    :
                    null
                 }
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className={`absolute z-10  transform -translate-x-0 ${ expand ? 'left-[160px]' : 'left-8'} ${v.childs.length >= 4 ? " -top-16 ": "top-0"} `}>
                    <div
                      className={`overflow-hidden   ${expand ? 'pl-9' : 'pl-5'}`}
                      onMouseEnter={onMouseEnter.bind(null, open)}
                      onMouseLeave={onMouseLeave.bind(null, open)}
                    >
                        <div className="rounded-md bg-white/95 border-[1px] border-slate-100 dark:border-slate-900 dark:bg-black/90 w-56 p-2">
                        {
                          v.childs.map((c, i) => {
                              const childIcon = MenuIcons(c.route)
                              return(
                                  <NavLink key={ i } to={c.route}>
                                    <div  className="cursor-pointer flex gap-2 p-2 dark:hover:bg-slate-900 hover:bg-slate-200 rounded-md ">
                                        <div className="dark:text-slate-50">{ childIcon }</div>
                                        <div className={`dark:text-slate-50 text-[15px] pt-[2px] text-sm `}>
                                            { c.name }
                                        </div>
                                    </div>
                                  </NavLink>
                              )
                          })
                      }
                            
                        </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </div>
            </>
          )
        }}
      </Popover>
      )
}



export const ProfileMenu = ({ icon, children, expand }) => {
    const buttonRef = useRef(null)
    const timeoutDuration = 200
    let timeout
    
    const closePopover = () => {
        return buttonRef.current?.dispatchEvent(
          new KeyboardEvent("keydown", {
            key: "Escape",
            bubbles: true,
            cancelable: true
          })
        )
      }
    
      const onMouseEnter = (open) => {
        clearTimeout(timeout)
        if (open) return
        return buttonRef.current?.click()
      }
    
      const onMouseLeave = (open) => {
        if (!open) return
        timeout = setTimeout(() => closePopover(), timeoutDuration)
      }

      return (
        <div className={`absolute border-none bottom-4 left-[13px] ${expand ? 'w-52' : ''}`}>
        
        <Popover className="relative" style={{ border: 'none' }}>
        {({ open }) => {
          return (
            <>
              <div onMouseLeave={onMouseLeave.bind(null, open)}>
                <Popover.Button
                  ref={buttonRef}
                  className={`
                  ${open ? "" : "text-opacity-90"}
                  
                  hover:text-opacity-100 focus:outline-none focus-visible:ring-0 hover:ring-0 ring-0 border-none active:border-none active:ring-0
                  flex gap-2 text-sm
                  `}
                  onMouseEnter={onMouseEnter.bind(null, open)}
                  onMouseLeave={onMouseLeave.bind(null, open)}
                  style={{ border: 'none' }}
                >
                 { icon }
                 {
                    expand ?
                    <div className=" dark:text-slate-50">
                        <div className="font-bold text-slate-700 text-sm dark:text-slate-50">Hendro Bintang</div>
                        <div className="text-left text-xs text-slate-700 dark:text-slate-50">Kasubag keu</div>
                    </div>
                    :
                    null
                 }
                </Popover.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-200"
                  enterFrom="opacity-0 translate-y-1"
                  enterTo="opacity-100 translate-y-0"
                  leave="transition ease-in duration-150"
                  leaveFrom="opacity-100 translate-y-0"
                  leaveTo="opacity-0 translate-y-1"
                >
                  <Popover.Panel className={`absolute z-10  transform -translate-x-0  ${ expand ? 'left-[160px]' : 'left-10'} -top-14 `}>
                    <div
                      className={`overflow-hidden pt-3  ${expand ? 'pl-[38px]' : 'pl-4'}`}
                      onMouseEnter={onMouseEnter.bind(null, open)}
                      onMouseLeave={onMouseLeave.bind(null, open)}
                    >
                        <div className="rounded-md bg-white/95 dark:bg-black/95 w-52 p-2">
                            {
                                children
                            }
                        </div>
                    </div>
                  </Popover.Panel>
                </Transition>
              </div>
            </>
          )
        }}
      </Popover>
      </div>

      ) 
}
export default MainMenu