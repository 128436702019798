
export const IconCard = ({ Icon, title, desc, color="bg-blue-600"}) => {
    return (
        <div className={" rounded-md p-3 py-4 flex gap-2 " + color}>
            <div>
                <Icon className="w-10 text-slate-50"/>
            </div>
            <div>
                <div className="font-bold text-slate-50">{ title }</div>
                <div className="text-xs text-slate-50">{ desc }</div>
            </div>
        </div>
    )
}
export const IconOutlineCard = ({ Icon, title, desc, className="", onClick=null}) => {
    return (
        <div onClick = { onClick } className={" rounded-md p-3 py-4 flex gap-2 " + className}>
            <div>
                <Icon className="w-10"/>
            </div>
            <div>
                <div className="font-bold ">{ title }</div>
                <div className="text-sm ">{ desc }</div>
            </div>
        </div>
    )
}

