import axios from 'axios'
import { handleResponse } from './Value'
import { backend } from './Value'
const headers = { "headers": { "content-type": "application/json" } } 

export const getAllSatker = () => {
    return handleResponse(axios.get(`${backend}/api/satker`))
}

export const createSatker = ({ namasatker, kodesatker, sik_url, sik_apikey, sik_req_logs, sik_target_logs }) => {
    const param = {
        namasatker: namasatker,
        kodesatker: kodesatker,
        sik_url: sik_url,
        sik_apikey: sik_apikey,
        sik_req_logs: sik_req_logs,
        sik_target_logs: sik_target_logs
    }

    return handleResponse(axios.post(`${backend}/api/satker`, param, headers))
}
export const putSatker = ({ id, namasatker, kodesatker, sik_url, sik_apikey, sik_req_logs, sik_target_logs }) => {
    const param = {
        id: id,
        namasatker: namasatker,
        kodesatker: kodesatker,
        sik_url: sik_url,
        sik_apikey: sik_apikey,
        sik_req_logs: sik_req_logs,
        sik_target_logs: sik_target_logs
    }
    return handleResponse(axios.put(`${backend}/api/satker`, param, headers))
}

export const deleteSatker = ({ id }) => {
    const param = {
        id: id
    }
    return handleResponse(axios.delete(`${backend}/api/satker`, { data: param }, headers))
}
