import { Link, useLocation } from 'react-router-dom'
import { ListTransaksi as ListTrans, TabTransaksi } from './Api/Deis'
import { useEffect, useState, Fragment, Component } from 'react'
import TitleBar from './Components/Menu/TitleBar'
import { subdomain, numberFormatReverse, satker, randomColor } from './Api/Value'
import Datatable, { ColSort } from './Components/Table/Datatable'
import { Select, SelectIcon, Text, Password } from './Components/Form/Input'
import { IconOutlineCard } from './Components/Container/ColorCard'
import { CreditCardIcon, UserIcon, KeyIcon } from '@heroicons/react/24/outline'
import { PrimaryButton } from './Components/Form/Button'
import { getCurrentUserData, updateUser, replacePassword } from './Api/User'
import { Spinner } from './Components/Container/Modal'
import toast from 'react-hot-toast'
import { connect } from 'react-redux'
class Profile extends Component{
    state = {
        id: "",
        loader: false,
        form: {
            name: "",
            username: "",
            password: "",
            retype_password: ""
        }
    }
    componentDidMount = () =>{
        this.getData();
    }
    getData = async () => {
        const data = await getCurrentUserData();
        if(data.code === 200){
            if(data.res.data){
                let form = this.state.form 
                form["username"] = data.res.data[0]["username"]
                form["name"] = data.res.data[0]["name"]
                this.setState({
                    form: form,
                    id: data.res.data[0]["id"]
                })
            }
        }
    }
    handleForm = (e) => {
        let form = this.state.form 
        const name = e.target.name 
        const value = e.target.value 
        form[name] = value
        this.setState({
            form: form
        })
    }
    simpan = async () => {
        let form = this.state.form 
        let valid = true
        if(form.username === ""){
            toast.error("Username required")
            valid = false
        }else{
            if(form.password !== ""){
                if(form.password !== form.retype_password){
                    toast.error("Password dan Retype Password tidak sama.")
                    valid = false
                }                
            }
        }

        if(valid){
            this.setState({
                loader: true
            })
            const d = await replacePassword({ password: form.password })
            if(d.code === 200){
                if(d.res.message){
                    toast.success(d.res.message)
                }
            }
            this.setState({
                loader: false
            })
        }
    }
    render(){
        return (
            <div>
               <TitleBar 
                    routing = {[]}
                    title   = "Profile"
                />
                <div className="max-w-md bg-white dark:bg-slate-900 rounded-md mt-2 p-6">
                    <center>
                        {
                            this.props.user ?
                                
                                <Fragment>
                                    {
                                        this.props.user.name ?
                                            <div className="w-24 h-24 text-5xl pt-[22px] rounded-full text-slate-50 p-4" style={{ background: randomColor(this.props.user.colorIndex) }}>
                                                { this.props.user.name.charAt(0).toUpperCase() }
                                            </div>
                                        :
                                            <div className="w-24 h-24 rounded-full bg-slate-200 p-4">
                                                <UserIcon className="text-slate-500"/>
                                            </div>
                                    }
                                    <div className="font-medium text-slate-800 dark:text-slate-50 text-md text-center my-2">{ this.props.user.name }</div>
                                </Fragment>
                            :
                            <div className="w-24 h-24 rounded-full bg-slate-200 p-4">
                                <UserIcon className="text-slate-500"/>
                            </div>
                        
                        }
                    </center>
                    
                    <div className="mb-3">
                        <div className="mb-1 text-sm text-slate-700 dark:text-slate-50">Username</div>
                        <Text disabled={ true } defaultValue={ this.state.form.username } Icon={ CreditCardIcon } onChange={ this.handleForm } placeholder="Username" name="username" />
                    </div>
               
                
                    <div className="mb-3">
                        <div className="mb-1 text-sm text-slate-700 dark:text-slate-50">Password</div>
                        <Text Icon={ KeyIcon } placeholder="Password" onChange={ this.handleForm } type="password" name="password"/>
                    </div>
                    <div className="mb-3">
                        <div className="mb-1 text-sm text-slate-700 dark:text-slate-50">Retype Password</div>
                        <Text Icon={ KeyIcon } placeholder="Retype Password" type="password" onChange={ this.handleForm } name="retype_password"/>
                    </div>
                    <div className="flex justify-end mt-5">
                        {
                            this.state.loader ?
                                <Spinner />
                            :

                                <PrimaryButton onClick={ this.simpan }>
                                    Simpan
                                </PrimaryButton>
                        }
                    </div>
                    
                </div>
            </div>
        )
    }
}

const App = ({ user }) => {
    return <Profile user={ user }/>
}
const mapStateToProps = state => {
     return ({
         user: state.globalReducer.user,
     })
 }
 
export default connect(mapStateToProps, {  })(App)