import { BarChart, Bar, Cell, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, LabelList } from 'recharts';
import React, { useState } from 'react'
const barColors = [ '#4c5f7c', '#dc2424', '#fcda00', '#8383ba', '#23dbbd' ];

const darkLineColor = "#64748B"
const lightLineColor = "#334155"
const App = ({ data, isDark }) => {
       
        return (
    
            <div className="text-sm w-[95%] h-90 relative pl-5">
                <div className="mb-7 mt-3 text-center">Serapan Anggaran Rp(M)</div>
                <ResponsiveContainer width="100%" height={300}>
                    <BarChart
                       width="100%"
                    barCategoryGap={1}
                    margin={{ top: 0, right: 0, left: -150, bottom: 0 }}
                        layout="vertical"
                        data={data}
                       
                    >
                    <CartesianGrid
                    stroke={ isDark ? "#475569" : "#ccc"}
                    strokeDasharray="3 3" />
                    <XAxis 
                        type="number"  
                        tick={{fill:  isDark ? darkLineColor : lightLineColor }} 
                        tickLine={{stroke:  isDark ? darkLineColor : lightLineColor }} 
                        axisLine = {{stroke:  isDark ? darkLineColor : lightLineColor }} 
                    />
           
                    <defs>
                        <linearGradient id="colorRealisasi" x1='0' y1='0' x2='100%' y2='0' spreadMethod='reflect'>
                            <stop offset='0' stopColor='#ffffff' />
                            <stop offset='1' stopColor='#9333EA' />
                        </linearGradient>
                        <linearGradient id="colorPagu" x1='0' y1='0' x2='100%' y2='0' spreadMethod='reflect'>
                            <stop offset='0' stopColor='#ffffff' />
                            <stop offset='1' stopColor='#059669' />
                        </linearGradient>
                    </defs>

                    <YAxis  type="category" width={150} padding={{ left: -20, top: 10, bottom: 10 }} hide dataKey="name"/>
                            
                    <Tooltip 
                        contentStyle={{ background: isDark ? "#334155" : "#F1F5F9" }}
                        cursor={{fill: isDark ? "#334155" : "#F1F5F9"}}
                    />
                    <Legend 
                        content={renderLegend}
                    />
                    <Bar animationDuration={200} dataKey="Realisasi" label={RealLabel}  layout="vertical" fill="url(#colorRealisasi)"/>
                    <Bar animationDuration={200} dataKey="Pagu" label={PaguLabel} layout="vertical" fill="url(#colorPagu)" />
                  </BarChart>
                </ResponsiveContainer>
                <br />
            </div>
        )
    }
    const RealLabel = (props) => {
    
        const {x, y, height, width, value} = props;
      
       
        return (
            <text name="" x={ 60} y={ y+65 } width={ width } height={ height } className="font-extrabold text-2xl" fill="#581C87"  textAnchor="middle">
                <tspan x={ xPad(value) }>
                    { value } M
                </tspan>
            </text>
        )
    }
        
    const PaguLabel = (props) => {
    
        const {x, y, height, value, width} = props;
    
        return (
            <text name="" x={  60} y={ y+65 }  height={ height } className="font-extrabold text-2xl" fill="#064E3B"  textAnchor="middle">
                <tspan x={ xPad(value) }>
                    { value } M
                </tspan>
            </text>
        )
    }
        
    const renderLegend = (props) => {
        const { payload } = props;
     
        return (
            <div className="flex justify-center">
                <ul className="flex gap-3 -mr-36">
                    {
                    payload.map((entry, index) => (
                        <li key={`item-${index}`} className="flex gap-2">
                            <div className={`${entry.dataKey === "Realisasi" ? "bg-purple-600" : "bg-emerald-600" } w-4 h-4 rounded-full`}></div>
                            {entry.value}
                        </li>
                    ))
                    }
                </ul>  
            </div>
          
        );
      }
    
   
    const xPad = (value) => {
        let len = `${value}`.length 
        let def = 60 
        if(len === 3){
            def = 56
        }else if(len === 4 ){
            def = 64
        }else if(len === 5){
            def = 71
        }else{
            def = 80
        }
        return def
    }
    export default App