
import reportWebVitals from './reportWebVitals';
import { useState, useEffect, Fragment } from 'react'
import { Provider } from 'react-redux'
import { handleBgLogin } from './Api/Value'
import store from './store'
import Routes from './Routes'
import { getCurrentUserData } from './Api/User'

import { Toaster } from 'react-hot-toast';


const dark = localStorage.getItem("dark") === "1"
const App = () => {
  useEffect(() => {
    handleBgLogin(dark)
    const el = document.getElementsByTagName("html")[0].classList
    if(dark === true){
        el.add("dark")
    } else {
        el.remove("dark")
    }
    return () => {
      
    };
  }, []);

  
  return (
    <Provider store={ store }>
      <div className="min-h-screen dark:[color-scheme:dark]">       
        <Routes />
      </div>
    </Provider>
  )
}

export default App;
