import { 
    CheckBadgeIcon, 
    HomeIcon, 
    ArrowRightCircleIcon, 
    ArrowLeftCircleIcon, 
    BuildingOfficeIcon, 
    CalendarIcon,
    ArrowTrendingUpIcon,
    ChartPieIcon,
    CurrencyDollarIcon,
    Cog6ToothIcon,
    CodeBracketIcon,
    AdjustmentsHorizontalIcon,
    GlobeAsiaAustraliaIcon,
    CalendarDaysIcon,
    UserGroupIcon } from '@heroicons/react/24/outline'

import { read, utils, writeFile } from 'xlsx';
import toast from 'react-hot-toast'

const theUrl    = window.location.hostname

export const subdomain = "/app"
export const backend   = process.env.REACT_APP_BACKEND_URL

export const handleResponse = (request) => {
   //return new Promise((resolve, reject) => {
            
          return  request.then((res) => {
                return ({ code: 200, res: res.data})
            }).catch((err) => {            
                console.log(err)
                if(err.config != undefined){
                    const url = new URL(err.config.url)
                    toast.error(`Failed (${err.config.method}) ${url.host}`)
                }
                
                return ({ message: err?.response?.data, code: err?.response?.status})
            })   
   
    
}
export const milyard = (str) => {
    if(str == NaN || str == "NaN" || str == undefined || str == null){
        return 0
    }
    let a = (parseInt(str) / 1000000000)
   return a.toFixed(2)
    // let hasil = Math.round((a + Number.EPSILON) * 100) / 100
    // return Number.isNaN(hasil) ? 0 : hasil
}
export const siteUrl = "https://sik.bpsdm.id/sbp/assets/bg-deis"
export const handleBgLogin = (bool) => {
    
    const bg = document.getElementById("bg-login");
    if(bg){
        
        bg.style.background     = (bool === false) ? `url('${siteUrl}/light.jpg')` : `url('${siteUrl}/dark.jpg')`
        bg.style.backgroundSize = "cover"
    }
    const bgIn = document.getElementById("bg-in")
    if(bgIn){
        
        bgIn.style.background     = (bool === false) ? `url('${siteUrl}/bg-in-light.jpg')` : `url('${siteUrl}/bg-in-black.jpg')`
        bgIn.style.backgroundSize = "cover"
    }
    
    const html = document.getElementsByTagName("html")
    html[0].style.colorScheme = (bool === true)  ? "dark" : "light"
}
export const configLoader = { loading: 'Loading', success: "", error: "Terjadi Kesalahan"}
export const MenuIcons = (dest) => {
    const parameter = [
        {
            "route": `${subdomain}/dashboard`,
            "icon": <HomeIcon className="w-[26px] "/>
        },
        {
            "route": `${subdomain}/pengeluaran`,
            "icon": <ArrowRightCircleIcon className="w-[26px] "/>
        },
        {
            "route": `${subdomain}/penerimaan`,
            "icon": <ArrowLeftCircleIcon className="w-[26px] "/>
        },
        {
            "route": `${subdomain}/aset`,
            "icon": <BuildingOfficeIcon className="w-[26px] "/>
        },
        {
            "route": `${subdomain}/kepegawaian`,
            "icon": <UserGroupIcon className="w-[26px] "/>
        },
        {
            "route": `${subdomain}/diklatSertifikasi`,
            "icon": <CheckBadgeIcon className="w-[26px] "/>
        },
        {
            "route": `${subdomain}/tabelPenyerapan`,
            "icon": <CalendarIcon className="w-[26px] "/>
        },
        {
            "route": `${subdomain}/preformanceRank`,
            "icon": <ArrowTrendingUpIcon className="w-[26px] "/>
        },
        {
            "route": `${subdomain}/grafikPenyerapan`,
            "icon": <ChartPieIcon className="w-[26px] "/>
        },
        {
            "route": `${subdomain}/posturAnggaran`,
            "icon": <CurrencyDollarIcon className="w-[26px] "/>
        },
        {
            "route": `${subdomain}/konfigurasi`,
            "icon": <Cog6ToothIcon  className="w-[26px]"/>
        },
        {
            "route": `${subdomain}/konfigurasiApi`,
            "icon": <CodeBracketIcon className="w-[24px]"/>
        },
        {
            "route": `${subdomain}/konfigurasiUser`,
            "icon": <UserGroupIcon className="w-[24px]"/>
        },
        {
            "route": `${subdomain}/konfigurasiRole`,
            "icon": <AdjustmentsHorizontalIcon className="w-[24px]"/>
        },
        {
            "route": `${subdomain}/globalParameter`,
            "icon": <GlobeAsiaAustraliaIcon className="w-[24px]"/>
        },
        {
            "route": `${subdomain}/jadwalRenkas`,
            "icon": <CalendarDaysIcon className="w-[24px]"/>
        },
        {
            "route": `${subdomain}/manajemenRenkas`,
            "icon": <CalendarDaysIcon className="w-[24px]"/>
        }
    ]
    const search = parameter.filter((x) => x.route === dest)
    return search.length === 0 ? "?" : search[0]['icon']
}

export const ColoringDeviasiTarget = (num) => {
    const danger    = "bg-red-500 dark:bg-red-600 text-slate-50"
    const warning   = "bg-yellow-400 dark:bg-yellow-500 text-slate-50"
    const normal    = "bg-lime-500 dark:bg-lime-600 text-slate-50"
    const safe    = "bg-blue-500 dark:bg-blue-600 text-slate-50"
    const white     = ""
    const toFloat   = parseFloat(num)
    const number    = num !== null && num !== "" && Number.isNaN(toFloat) !== true ? toFloat : false; 
    return (number < -3.0 ? danger
            : 
            number <= -1.0 && number >= -3 ? warning
            :  
            number > -1.0 && number <= 0 ? normal
            : 
            number > 0 ? safe
            : white)
}

export const listBulan = ["Jan", "Feb", "Mar", "Apr", "Mei", "Jun", "Jul", "Agu", "Sep", "Okt", "Nov", "Des"]
export const listBulanFull = ["Januari", "Februari", "Maret", "April", "Mei", "Juni", "Juli", "Agustus", "September", "Oktober", "November", "Desember"]
export const kabisat = () => {
    const year = new Date().getFullYear()
    let isKabisat = false;
    if(year % 400 === 0){
        isKabisat = true
    }else if(year % 100 === 0){
        isKabisat = false
    }else if(year % 4 === 0){
        isKabisat = true
    }
    return isKabisat
}

export const exportExcel = ({ object, title="Deis 2.0", pattern }) => {
    let result = []
    if(pattern === undefined){
        result  = object
    }else{
        for(let w of object){
            let obj = {}
            for(let p of pattern){
                obj[p.column] = w[p.key]
            }    
            result.push(obj)
        }
    }
    const ws = utils.json_to_sheet(result);
    /* create workbook and append worksheet */
    const wb = utils.book_new();
    
    utils.book_append_sheet(wb, ws, "Data");
    /* export to XLSX */
    writeFile(wb, title + ".xlsx");
}

export const numberFormatReverse = (string, float=false) => {
  let toInt =  NaN;
  let comma = NaN;
  if (float) {
    let comaNumber = string.split(".")
        comma = comaNumber[comaNumber.length-1];
    let primaryNumber = string.split(".")
        toInt = primaryNumber.slice(0, primaryNumber.length-1).join("");
  }else{
    let primaryNumber = string.replaceAll(".","").replaceAll(",","")
      toInt = parseInt(primaryNumber);
  }
//   console.log(toInt)
  if (isNaN(toInt)) {
      return "0";
  } else {
      let pars = new Intl.NumberFormat('en-CA',{style:'decimal'}).format(toInt).replaceAll(",",".");
      if(float){
      
          if(!isNaN(comma)){
              return pars + "," + comma
          }else{
              return pars
          }
      }
      return pars
  }
}
export const numberFormat = (string, float=false) => {
    let toInt = (float) ? parseInt(`${string}`.split(".")[0].replaceAll(",","")) : parseInt(`${string}`.replaceAll(".", "").replaceAll(",", ""));
    if (isNaN(toInt)) {
        return "0";
    } else {
        let pars = new Intl.NumberFormat('en-CA',{style:'decimal'}).format(toInt);
        if(float){
           const getAfterComma =  `${string}`.split(".")

            if(getAfterComma.length > 1){
                return pars + "." + getAfterComma[1]
            }else{
                return pars
            }
        }
        return pars
    }
}

export const satker = [
    { key: "Set. BPSDM", target: "https://ika.bpsdm.id/sbp/index.php?/deis" },
    { key: "PEM Akamigas", target: "https://mauren.akamigas.ac.id/index.php?/deis" },
    { key: "PPSDM KEBTKE", target: "https://ika.bpsdm.id/kebtke/index.php?/deis" },
    { key: "PPSDM Geominerba", target: "https://ika.bpsdm.id/geominerba/index.php?/deis" },
    { key: "PEP Bandung", target: "https://ika.bpsdm.id/pep/index.php?/deis" },
    { key: "PPSDM Aparatur", target: "https://ika.bpsdm.id/aparatur/index.php?/deis" },
    { key: "BDTBT", target: "https://ika.bpsdm.id/bdtbt/index.php?/deis" },
]
let gradient, width, height
export const getGradient = ({ctx, chartArea, color1, color2}) => {
  const chartWidth = chartArea.right - chartArea.left;
  const chartHeight = chartArea.bottom - chartArea.top;
  if (!gradient || width !== chartWidth || height !== chartHeight) {
    // Create the gradient because this is either the first render
    // or the size of the chart has changed
    // width = chartWidth;
    // height = chartHeight;
     gradient = ctx.createLinearGradient(chartArea.left, 0, chartArea.right, 0);
    gradient.addColorStop(0, color1);
    gradient.addColorStop(1, color2);
  }

  return gradient;
}

export const  nextChar = (c) => {
    return String.fromCharCode(c.charCodeAt(0) + 1);
}
export const bulan= {
    jan: "Januari",
    feb: "Februari",
    mar: "Maret",
    apr: "April",
    mei: "Mei",
    jun: "Juni",
    jul: "Juli",
    agu: "Agustus",
    sep: "September",
    nov: "November",
    des: "Desember",
}
export const lastMonthDate = (intM=false) => {
    let y     = new Date()
    var month = (intM) ? intM : y.getMonth(); // January
    var d = new Date(y.getFullYear(), month + 1, 0);
    var m = d.getMonth()+1
    if(m < 10){
        m = `0${m}`
    }
    return (`${d.getFullYear()}-${m}-${d.getDate()}`); // last day in January
}
export const valueLine = (raw, target, type, lineType) => {
    if(raw[type][target]){
        
        return raw[type][target][lineType]
    }
    return 0
}
export const randomColor = (index=0) => {
    const col         = ["#DC2626", "#16A34A", "#2563EB", "#4F46E5", "#C026D3"]
    
    return col[index]
}