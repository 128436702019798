import { 
LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
  ResponsiveContainer,
  LabelList
 } from 'recharts';
import { useState } from 'react'

const darkLineColor = "#64748B"
const lightLineColor = "#334155"
const App = ({ data, isDark }) => {
    const [ hideLine, setHideLine ] = useState({ realisasi: false, target: false, target_semula: true })
    const handleClick = (evt) => {
        
        if(evt.dataKey === "Realisasi"){
            setHideLine(prevState => ({ ...prevState, realisasi: !hideLine.realisasi }))
        }else if(evt.dataKey === "Target"){
            setHideLine(prevState => ({ ...prevState, target: !hideLine.target }))
            
        }else if(evt.dataKey === "Target Semula"){
            setHideLine(prevState => ({ ...prevState, target_semula: !hideLine.target_semula }))
            
        }
    }
    return (

        <div className="text-sm  sm:w-[95%] h-96 relative pl-5">
            <div className="absolute top-[43%] text-xs -left-6 -rotate-90">Rupiah (M)</div>
            <ResponsiveContainer width="100%" height={ 430 }>
            <LineChart  margin={{ top: 30, right: 20, left: 0 }} data={data}>
                <Line animationDuration={200} hide={ hideLine.realisasi } type="monotone" dataKey="Realisasi" strokeWidth={3} dot={ <DotRealisasi /> } stroke="#F59E0B">
                    <LabelList content={<LabelRealisasi />} />
                </Line>
                <Line animationDuration={200} hide={ hideLine.target } type="monotone" dataKey="Target" stroke="#1D4ED8" dot={ <DotTarget /> } strokeWidth={3}>
                    <LabelList content={<LabelTarget />} />
                </Line>
                <Line animationDuration={200} hide={ hideLine.target_semula } type="monotone" dataKey="Target Semula" stroke="#16A34A" dot={ <DotSemula /> } strokeWidth={3}>
                    <LabelList content={<LabelSemula />} />
                </Line>
                <CartesianGrid stroke={ isDark ? "#475569" : "#ccc"} strokeDasharray="3 3" />
                <XAxis 
                    tick={{fill:  isDark ? darkLineColor : lightLineColor }} 
                    tickLine={{stroke:  isDark ? darkLineColor : lightLineColor }} 
                    axisLine = {{stroke:  isDark ? darkLineColor : lightLineColor }} 
                    dataKey="name" tickSize={ 20 } 
                />
                <YAxis 
                    tick={{fill:  isDark ? darkLineColor : lightLineColor }} 
                    tickLine={{stroke:  isDark ? darkLineColor : lightLineColor }} 
                    axisLine = {{stroke:  isDark ? darkLineColor : lightLineColor }} 
                    tickSize={20}/>

                <Tooltip />
                <Legend content={<RenderLegend isDark={ isDark } />} onClick={ handleClick } height={100} margin={{ top: 100 }}/>
            </LineChart>
            </ResponsiveContainer>
        </div>
    )
}

const RenderLegend = (props) => {
    const { payload,onClick, isDark } = props;
    const disabled =  "#64748B"
    return (
        <div className="flex justify-center">
            <ul className="mt-5 flex gap-4">
                {
                    payload.map((entry, index) => (
                        <li style={{ color: (entry.inactive === false) ? entry.color : "#CBD5E1" }} key={`item-${index}`} className="flex gap-1 font-bold" onClick={ () => onClick(entry) }>
                            <div className="w-7 pt-2">
                                <hr className="border-2 rounded-md" style={{ borderColor: (entry.inactive === false) ? entry.color : disabled }}/>                           
                            </div>
                            <div style={{ color: (entry.inactive === false) ? entry.color : disabled }}>
                                {entry.value}                        
                            </div>
                        </li>
                    ))
                }
            </ul>
        </div>
      
    );
  }
const LabelTarget = (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y - 5} dy={-4} fill="#1D4ED8" className="font-bold" fontSize={12}  textAnchor="middle">
      {value} M
    </text>
  );
};


const LabelSemula= (props) => {
  const { x, y, stroke, value } = props;

  return (
    <text x={x} y={y + 25} dy={-4} fill="#16A34A" className="font-bold" fontSize={12}  textAnchor="middle">
      {value} M
    </text>
  );
};


const LabelRealisasi = (props) => {
  const { x, y, stroke, value } = props;
    if(value === undefined){
        return null
    }
  return (
    <text x={x} y={y+25} dy={-4} fill="#D97706" className="font-bold" fontSize={12}  textAnchor="middle">
      {value} M
    </text>
  );
};

const DotRealisasi = (props) => {
    const { cx, cy, value } = props;
    if(value === undefined){
        return null
    }
    return (
        <svg height="90" width="90"  
            x={cx - 4}
            y={cy - 4}
            viewBox="0 0 1024 1024"
            >
            <circle cx="50" cy="50" r="40"  fill="#F59E0B" />
        </svg>
    )
}

const DotTarget = (props) => {
    const { cx, cy, value } = props;
    return (
        <svg height="90" width="90"  
            x={cx - 4}
            y={cy - 4}
            viewBox="0 0 1024 1024"
            >
            <circle cx="50" cy="50" r="40"  fill="#1D4ED8" />
        </svg>
    )
}

const DotSemula = (props) => {
    const { cx, cy, value } = props;
    return (
        <svg height="90" width="90"  
            x={cx - 4}
            y={cy - 4}
            viewBox="0 0 1024 1024"
            >
            <circle cx="50" cy="50" r="40"  fill="#16A34A" />
        </svg>
    )
}

export default App