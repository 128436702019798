import { UserIcon, UsersIcon, SunIcon, ArrowUturnLeftIcon, ChevronRightIcon,
    Cog6ToothIcon
} from '@heroicons/react/24/outline'
import { MoonIcon } from '@heroicons/react/24/solid'
import MainMenu, { ProfileMenu } from './ListMenu'
import { useState, useRef, useEffect, Fragment } from 'react'
import MobileNav from './MobileNav'
import { NavLink } from 'react-router-dom'
import { subdomain, backend, randomColor, siteUrl } from '../../Api/Value'
import { connect } from 'react-redux'

const Nav = ({ toggleDark, isDark, listNav, user }) => {

    const  buttonRef = useRef(null)
    const [ expand, setExpand ] = useState(false);
    const [ mainExpand, setMainExpand ] = useState(false)
    const delayExpand  = (bool) => {
        if(bool === false){
            setExpand(false)
            setTimeout(() => {
                setMainExpand(false)
            }, 100);
        }else if(bool === true){
            setMainExpand(true)
            setTimeout(() => {
                setExpand(true)
            }, 200);
        }
    }

    useOutsideAlerter(buttonRef, mainExpand, delayExpand);
    return (
        <div>
        <MobileNav isDark={ isDark } user={ user } toggleDark={ toggleDark } list={listNav} />
        <div id={ expand ? "e-1" : "e-0" } ref={ buttonRef } className={`p-3 left-2 top-2 hidden sm:block z-10 bg-white dark:bg-black/30 fixed rounded-lg min-h-[96%] transition-all duration-300 ${ mainExpand ? 'w-52' :'w-16'} bg-opacity-50 backdrop-blur-xl`}>
            <div className="w-full relative">
                <button id="navigation-toggle" onClick={ () => delayExpand(!mainExpand) } className={`absolute cursor-pointer ${mainExpand ? 'hidden' : '' } dark:bg-slate-500 dark:text-white bg-white rounded-full w-4 h-4 -right-5 top-2 p-[2px] `} >
                    <ChevronRightIcon className={ `w-3 h-3 ${expand ? 'rotate-180' : ''}`}/>
                </button>
                <div>
                        <button  className="pl-[2px] flex cursor-pointer" onClick={ () => delayExpand(!mainExpand) }>
                            <div>
                                <img src={ `${siteUrl}/esdm.png` } className="w-9" alt="" />
                            </div>
                            {
                                expand ?
                                <div className="pl-3">
                                    <div className="pt-2 pl-3 font-bold text-slate-700 dark:text-slate-300">
                                        DEIS 3.0                                
                                    </div>
                                    <div>
                                        <div className="absolute dark:bg-slate-500 dark:text-white pt-1 bg-white rounded-full w-5 h-5 right-4 top-2 p-[2px]" onClick={ () => delayExpand(!mainExpand) }>
                                            <ChevronRightIcon className="w-4 h-3 rotate-180"/>
                                        </div>                  
                                    </div>
                                </div>
                                : 
                                null
                            }
                        </button>
                        <div className="text-[9px] pl-[7px] text-slate-700 border-t-[1px] dark:border-slate-700 dark:text-slate-400 border-slate-200 font-medium mt-3 pt-2">
                            MENU
                        </div>
                        <div className="mt-2 pl-[3px]">
                            <MainMenu list={ listNav.top } expand={ expand }/>
                        </div>
                        
                </div>
                
            </div>
            <div className="w-full">
             
                    <div className="border-t-[1px] pl-[3px] mb-2 mt-14 border-slate-300/25 dark:border-slate-600 dark:text-slate-400 font-medium text-[9px] pt-2">
                            SYSTEM
                    </div>
                    <div onClick={ toggleDark }  className={`h-9 cursor-pointer ${ expand ? "w-full" : "w-9"} flex gap-2 text-sm p-[4px] pl-[6px] dark:hover:bg-slate-800 hover:bg-slate-50/40 rounded-lg `}>
                        {
                            isDark ?
                            <MoonIcon className="w-[25px] text-slate-700 dark:text-yellow-400" />
                            :
                            <SunIcon className="w-[26px] text-slate-700 dark:text-slate-300" />

                        }
                        {
                        expand ?
                            <div className="pt-[3px] dark:text-slate-50 text-slate-900">
                                { isDark ? "Mode Gelap" : "Mode Terang"}
                            </div>
                        :
                            null
                        }
                    </div>  
                    <div className="mt-2 pl-[3px]">
                        <MainMenu list={ listNav.bottom } expand={ expand }/> 
                    </div>
             
            </div>
            <ProfileMenu 
                expand = { expand }
                icon={
                    
                        user ?
                        <Fragment>
                            {
                                user.name ?
                                    <div className="w-10 h-10 relative rounded-full text-[20px] text-slate-50" style={{ background: randomColor(user.colorIndex) }}>
                                        <div className="absolute top-[24%] text-center w-full">
                                            { `${user.name}`.charAt(0).toUpperCase() }
                                        </div>
                                    </div>
                                :
                                    <div className="w-10 h-10 rounded-full bg-slate-100 dark:bg-slate-400 p-[5px] pl-[7px]">
                                        <UserIcon className="w-[26px] text-slate-700 dark:text-slate-800" />
                                    </div>
                            }
                            
                        </Fragment>
                        :

                        <div className="w-10 h-10 rounded-full bg-slate-100 dark:bg-slate-400 p-[5px] pl-[7px]">
                            <UserIcon className="w-[26px] text-slate-700 dark:text-slate-800" />
                        </div>
                    
                }>
                <NavLink to={`${subdomain}/profile`}>
                <div className="flex gap-2 p-2 dark:hover:bg-slate-900 hover:bg-slate-200 rounded-md">
                    <div>
                        <UserIcon className="w-[26px] text-slate-700 dark:text-slate-300"/>
                    </div>
                    <div className="text-[15px] pt-[2px] dark:text-slate-50 text-sm">
                        Profil
                    </div>
                </div>
                </NavLink>
                <a href={`${backend}/api/logout`}>
                <div className="flex gap-2 p-2 dark:hover:bg-slate-900 hover:bg-slate-200 rounded-md">
                    <div>
                        <ArrowUturnLeftIcon className="w-[26px] text-slate-700 dark:text-slate-300"/>
                    </div>
                    
                        <div className="text-[15px] pt-[2px] dark:text-slate-50 text-sm">
                            Logout
                        </div>
                    
                </div>
                </a>
                
            </ProfileMenu>
            
        </div>
        </div>

    )
}

function useOutsideAlerter(ref) {
  useEffect(() => {
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
            if(ref.current.id === "e-1"){
                document.getElementById("navigation-toggle").click()
            }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}


const mapStateToProps = state => {
   // console.log("FROM PROPS", state)
    return ({
        user: state.globalReducer.user,
    })
}

  
export default connect(mapStateToProps, {  })(Nav) 