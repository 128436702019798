import axios from 'axios'
import { handleResponse } from './Value'
import { backend } from './Value'
const headers = { "headers": { "content-type": "application/json" } } 

export const getAllUser = () => {
    return handleResponse(axios.get(`${backend}/api/user/all`))
}

export const getCurrentUserData =  () => {
    return handleResponse(axios.get(`${backend}/api/user/current`))
}

export const createUser = ({ username, password, role, kodesatker, active, name }) => {
    let param = { username: username, password: password, role: role, kodesatker:kodesatker, active: active, name: name }
    return handleResponse(axios.post(`${backend}/api/user/one`, param))
}

export const setUser = ({ id, username, password, role, kodesatker, active, name }) => {
    let param = { id: id, username: username, password: password, role: role, kodesatker:kodesatker, active: active,  name: name }
    
    return handleResponse(axios.put(`${backend}/api/user/one`, param))
}


export const updateUser = (props) => {
    let param = { username: props.username, id: props.id}
    if(props.password !== ""){
        param["password"] = props.password
    }
    return handleResponse(axios.put(`${backend}/api/user/one`, param))
}
export const deleteUser = ({ id }) => {
    let param = {
        data: { id: id }
    }
        
    return handleResponse(axios.delete(`${backend}/api/user/one`, param))

}
export const replacePassword = ({password}) => {
    let param = {
        password: password
    }
    
    return handleResponse(axios.put(`${backend}/api/user/one/password`, param))
}