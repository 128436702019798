import Nav from './Components/Menu/Nav'
import TitleBar from './Components/Menu/TitleBar'
import { IconCard } from './Components/Container/ColorCard'
import { 
    CalendarIcon, 
    ArrowTrendingUpIcon, 
    CurrencyDollarIcon, 
    ChartPieIcon,
    CheckBadgeIcon,
    UsersIcon,
    BuildingOfficeIcon,
    UserGroupIcon,
    CalendarDaysIcon
} from '@heroicons/react/24/outline'
import { connect } from "react-redux";
import { subdomain } from './Api/Value'
import { NavLink } from 'react-router-dom'


const Dashboard = ({ toggleDark, menuDash }) => {
    return (
        <div >
            <div className="mb-2">
                <TitleBar 
                    routing = {[]}
                    title   = "Dashboard"
                />
            </div>
            <MiniCard menuDash={ menuDash }/>           
            <div className="bg-white/60 dark:bg-black/50 rounded-md mt-3">
                <div className="text-center py-2 dark:text-slate-50">Status Sinkronisasi Aplikasi</div>
                <div className="bg-white dark:bg-slate-800 rounded-b-md p-3">
                    <div className="h-20"></div>
                </div>
            </div>
        </div>
    )
}

const MiniCard  =({ menuDash }) => {
    const mappingCard = [
        { Icon: CalendarIcon, route: `${subdomain}/tabelPenyerapan`, title: "Tabel Penyerapan", desc: "Lorem ipsum dolor sit atmet" },
        { Icon: ArrowTrendingUpIcon, route: `${subdomain}/preformanceRank`, title: "Performance Rank", desc: "Lorem ipsum dolor sit atmet" },
        { Icon: ChartPieIcon, route: `${subdomain}/grafikPenyerapan`, title: "Grafik Penyerapan", desc: "Lorem ipsum dolor sit atmet" },
        { Icon: CurrencyDollarIcon, route: `${subdomain}/posturAnggaran`, title: "Postur Anggaran", desc: "Lorem ipsum dolor sit atmet" },
        { Icon: CheckBadgeIcon, route: `${subdomain}/diklatSertifikasi`, title: "Diklat & Sertifikasi", desc: "Lorem ipsum dolor sit atmet" },
        { Icon: UsersIcon, route: `${subdomain}/konfigurasiUser`, title: "Manajemen User", desc: "Lorem ipsum dolor sit atmet" },
        { Icon: BuildingOfficeIcon, route: `${subdomain}/aset`, title: "Aset", desc: "Lorem ipsum dolor sit atmet" },
        { Icon: UserGroupIcon, route: `${subdomain}/kepegawaian`, title: "Kepegawaian", desc: "Lorem ipsum dolor sit atmet" },
        { Icon: CalendarDaysIcon, route: `${subdomain}/jadwalRenkas`, title: "Jadwal Renkas", desc: "Lorem ipsum dolor sit atmet" },
    ]
    return (
        <div className="bg-white/60 dark:bg-black/50 rounded-md grid gap-3 p-3 sm:grid-cols-1 md:grid-cols-2 xl:grid-cols-4">
            {
                mappingCard.filter((a) => {
                    
                    return menuDash.indexOf(a.route) >= 0
                }).map((x, i) => {
                   return <NavLink to={ x.route } key={i}>
                        <IconCard 
                            Icon={x.Icon} 
                            title={ x.title }
                            desc={ x.desc }
                        />        
                    </NavLink>
                })
            }
        </div>
    )
}

const mapStateToProps = state => ({
    listNav: state.globalReducer.listNav,
    menuDash: state.globalReducer.menuDash
})
  
export default connect(mapStateToProps, {  })(Dashboard)