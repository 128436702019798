import { XMarkIcon } from '@heroicons/react/24/outline'
import { NavLink } from 'react-router-dom'
const GagalLogin = () => {
    return (
        <div className="flex justify-center min-h-screen bg-slate-100">
            <div className=" w-1/2">
                <div className="min-h-screen grid grid-cols-1 content-center">
                    <div className="p-4 text-center bg-white rounded-md shadow-md">
                        <div className="text-center text-red-600">
                            <center>
                                <XMarkIcon className="w-20" />                        
                            </center>
                        </div>
                        <div className="text-xl">
                            Terjadi Kesalahan Dalam Proses Login Anda                    
                        </div>
                        <NavLink to="/login">
                            <button className="w-1/2 bg-blue-600 rounded-md py-2 mt-4 mb-4 text-slate-50 shadow-md active:opacity-50">Coba Lagi</button>
                        </NavLink>
                    </div>
                </div>
            </div>
        </div>
        
    )
}

export default GagalLogin