import { ArrowLeftCircleIcon } from '@heroicons/react/24/outline'

import { NavLink } from 'react-router-dom'
const TitleBar = ({ routing, title, children }) => {
    return (
        <div className="bg-white dark:bg-slate-800 w-full p-2 rounded-md flex px-2 justify-between gap-2">
            <div className="flex w-full gap-2">
                {
                    routing.length === 0 ?
                        <div></div>
                    :
                        <NavLink to={ routing[routing.length-1]['route'] }>
                            <ArrowLeftCircleIcon className="w-9 text-blue-600"/>
                        </NavLink>

                }
                <div>
                    <div className="text-lg py-0 font-medium text-slate-800 dark:text-slate-50">{ title }</div>
                    <div className="text-slate-600 py-0 text-xs dark:text-slate-50">
                        {
                            routing.map((x, i) => {
                                return (
                                    <NavLink to={ x.route } key={i}>
                                        <span className="font-medium text-blue-700">{ x.name }</span>&nbsp;/&nbsp; 
                                    </NavLink>

                                )
                            })
                        }
                        { title }  
                    </div>
                </div>
            </div>
            <div>
                { children }
            </div>
        </div>
    )
}

export default TitleBar