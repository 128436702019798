import { Component, Fragment } from 'react'
import { valueLine, ColoringDeviasiTarget } from '../../../Api/Value'
let dump = []
for (let b = 0; b < 12; b++) {
   dump.push(b)
}

class LineTabel extends Component{
    render(){
        const data = this.props.data
        const isDark = this.props.isDark
        return(
            <div className="rounded-md p-3 mt-3 text-sm overflow-auto dark:bg-slate-800 bg-white">
                <table className="w-full">
                    <thead>
                        <tr>
                            <td rowSpan="2" colSpan={2} className="rounded-l-md bg-green-500 text-white border-[1px] border-slate-50 dark:border-slate-800 text-center p-3">Bulan</td>
                            <ColBulan>Januari</ColBulan>
                            <ColBulan>Februari</ColBulan>
                            <ColBulan>Maret</ColBulan>
                            <ColBulan>April</ColBulan>
                            <ColBulan>Mei</ColBulan>
                            <ColBulan>Juni</ColBulan>
                            <ColBulan>Juli</ColBulan>
                            <ColBulan>Agustus</ColBulan>
                            <ColBulan>September</ColBulan>
                            <ColBulan>Oktober</ColBulan>
                            <ColBulan>November</ColBulan>
                            <ColBulan className="rounded-r-md">Desember</ColBulan>
                        </tr>
                        <tr>
                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan colSpan={0}>Kumulatif</ColBulan>

                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan colSpan={0}>Kumulatif</ColBulan>

                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan colSpan={0}>Kumulatif</ColBulan>

                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan colSpan={0}>Kumulatif</ColBulan>

                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan colSpan={0}>Kumulatif</ColBulan>

                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan colSpan={0}>Kumulatif</ColBulan>

                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan colSpan={0}>Kumulatif</ColBulan>

                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan colSpan={0}>Kumulatif</ColBulan>

                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan colSpan={0}>Kumulatif</ColBulan>

                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan colSpan={0}>Kumulatif</ColBulan>

                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan colSpan={0}>Kumulatif</ColBulan>

                            <ColBulan colSpan={0}>Bulanan</ColBulan>
                            <ColBulan className="rounded-r-md" colSpan={0}>Kumulatif</ColBulan>

                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                    
                    
                            <td colSpan={2} className="rounded-l-md bg-blue-500 text-white text-center border-[1px] border-slate-50 dark:border-slate-800 p-3">Target</td>
                            {
                                dump.map((x) => {
                                    return (
                                        <Fragment key={ x }>
                                            <ColTarget>{ valueLine(data, x, "non_kumulatif", "Target") }</ColTarget>
                                            <ColTarget className={ x === 11 ? "rounded-r-md" : "" }>{ valueLine(data, x, "kumulatif", "Target") }</ColTarget>
                                        </Fragment>
                                    )
                                })
                            }
                            
                           
                        </tr>
                    </tbody>

                    <tbody>
                        <tr>
                            <td rowSpan={ 3 } className="rounded-l-md bg-yellow-500 text-white border-[1px] border-slate-50 dark:border-slate-800 p-1 py-5">
                                <div className="-rotate-90">Realisasi</div>
                            </td> 
                        </tr>
                       <tr>
                            <td className=" bg-yellow-500 text-white border-[1px] border-slate-50 dark:border-slate-800 p-1 py-2">
                                Nilai
                            </td> 
                            {
                                dump.map((x) => {
                                    return (
                                        <Fragment key={ x }>
                                            <ColReal>{ valueLine(data, x, "non_kumulatif", "Realisasi") }</ColReal>
                                            <ColReal className={ x === 11 ? "rounded-r-md" : "" }>{ valueLine(data, x, "kumulatif", "Realisasi") }</ColReal>
                                        </Fragment>
                                    )
                                })
                            }
                       
                       </tr>
                       <tr>
                            
                            <td className=" bg-yellow-500 text-white text-center border-[1px] border-slate-50 dark:border-slate-800 p-1 py-2">
                                %
                            </td> 
                            {
                                dump.map((x) => {
                                    return (
                                        <Fragment key={ x }>
                                            <ColReal>{ valueLine(data, x, "non_kumulatif", "Realisasi Persen") }</ColReal>
                                            <ColReal className={ x === 11 ? "rounded-r-md" : "" }>{ valueLine(data, x, "kumulatif", "Realisasi Persen") }</ColReal>
                                        </Fragment>
                                    )
                                })
                            }
                        </tr>


                    </tbody>

                    <tbody>
                        <tr>
                            <td rowSpan={ 3 } className="rounded-l-md bg-purple-500 text-white border-[1px] border-slate-50 dark:border-slate-800 pb-4">
                                <div className="-rotate-90">Deviasi</div>
                            </td> 
                        </tr>
                       <tr>
                            <td className=" bg-purple-500 text-white border-[1px] border-slate-50 dark:border-slate-800 p-1 py-2">
                                Nilai
                            </td> 
                            {
                                dump.map((x) => {
                                    const val_nk  = valueLine(data, x, "non_kumulatif", "Deviasi")
                                    const color_nk = ColoringDeviasiTarget(val_nk)

                                    const val_k  = valueLine(data, x, "kumulatif", "Deviasi")
                                    const color_k = ColoringDeviasiTarget(val_k)
                                    return (
                                        <Fragment key={ x }>
                                            <ColDev className={ color_nk }>{ val_nk }</ColDev>
                                            <ColDev className={ `${color_k} ${x === 11 ? "rounded-r-md" : ""}` }>{ val_k }</ColDev>
                                        </Fragment>
                                    )
                                })
                            }
                       </tr>
                       <tr>
                            <td className=" bg-purple-500 text-center text-white border-[1px] border-slate-50 dark:border-slate-800 p-1 py-2">
                                %
                            </td> 
                            {
                                dump.map((x) => {
                                    const val_nk  = valueLine(data, x, "non_kumulatif", "Deviasi Persen")
                                    const color_nk = ColoringDeviasiTarget(val_nk)

                                    const val_k  = valueLine(data, x, "kumulatif", "Deviasi Persen")
                                    const color_k = ColoringDeviasiTarget(val_k)
                                    return (
                                        <Fragment key={ x }>
                                            <ColDev className={ `${color_nk}` }>{ val_nk }</ColDev>
                                            <ColDev className={ `${color_k} ${x === 11 ? "rounded-r-md" : ""}` }>{ val_k }</ColDev>
                                        </Fragment>
                                    )
                                })
                            }
                        
                           
                        </tr>


                    </tbody>
                </table>
            </div>
        )
    }
}
const ColDev = ({ children, className }) => {
    return (
        <td className={` text-white border-[1px] text-center border-slate-50 dark:border-slate-800 p-2 py-2 ${ className }`}>
            { children }
        </td>
    )
}
const ColReal = ({ children, className }) => {
    return (
        <td className={`bg-yellow-500 text-white border-[1px] text-center border-slate-50 dark:border-slate-800 p-2 py-3 ${className}`}>
            { children }
        </td>
    )
}
const ColTarget = ({ children, className }) => {
    return (
        <td  className={`p-3 text-center bg-blue-500 text-slate-50 border-[1px] border-slate-50 dark:border-slate-800 ${className}`}>
            { children }
        </td>
    )
}


const ColBulan = ({ children, colSpan=2, className }) => {
    return (
        <td colSpan={ colSpan } className={`p-3 text-center bg-green-500 text-slate-50 border-[1px] dark:border-slate-800 border-slate-50 ${className}`}>
            {
                children
            }
        </td>
    )
}

const App = ({ data, isDark }) => {
    return <LineTabel data={ data } isDark={ isDark } />
}

export default App 