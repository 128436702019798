import { Component, Fragment } from 'react'
import { getAllSatker } from '../../Api/Satker'
import { grafikPosturTabel } from '../../Api/Deis'
import axios from 'axios'
import { numberFormat } from '../../Api/Value'
import { Tabs } from './Tabs'

let rawResponse = []
let source      = null
let strObj = JSON.stringify({ rm: { belanja_pegawai: { pagu: 0, realisasi: 0 }, belanja_barang: { operasional: { pagu: 0, realisasi: 0 },kegiatan: { pagu: 0, realisasi: 0 } }, belanja_modal: { pagu: 0, realisasi: 0 } }, blu: { belanja_barang: { pagu: 0, realisasi: 0 }, belanja_modal: { pagu: 0, realisasi: 0 }},total: 0})
class Datatabel extends Component{
    state = {
        data: [],
        total: JSON.parse(strObj),

        type: "pagu_akhir"
    }
    componentDidMount = () => {
        this.load()
    }
    
    handleData = (type, target) => {
        this.setState({
            type: target
        }, () => {
            this.getGrafik(target)
        })
    }
    load = async () => {
        const g = await getAllSatker()
        if(g.code === 200){
            const { data } = g.res 
            this.setState({
                data: data
            }, () => {
                this.getGrafik()
            })
        }
    }
    responseTabel = (res, d) => {
        if(res.code === 200){
            rawResponse.push({ ...res.res.data, satker: d.namasatker, kodesatker: d.kodesatker})
        }else{
            rawResponse.push(false)
        }
        if(rawResponse.length === this.state.data.length){
            this.joinRaw()
        }
    }
    joinRaw = () => {
        let data = this.state.data 
        let objTfoot = JSON.parse(strObj)
        let type     = "pagu"
        for(let r of rawResponse){
            
            let obj = JSON.parse(strObj)
            if(r !== false){        
                for(let p of r[type]){
                    const { id, jumlah } = p
                    
                    let num  = (jumlah === null) ? 0 : jumlah
                    
                    obj["total"] += parseInt(num)
                    // id 1 rm.belanja_pegawai.type
                    if(id === "1"){
                        obj["rm"]["belanja_pegawai"][type]       = parseInt(num)
                        objTfoot["rm"]["belanja_pegawai"][type] += parseInt(num)
                    }
                    // id 2 rm.belanja_barang.operasional.type
                    if(id === "2"){
                        obj["rm"]["belanja_barang"]["operasional"][type] = parseInt(num)
                        objTfoot["rm"]["belanja_barang"]["operasional"][type] += parseInt(num)
                    }
                    // id 3 rm.belanja_barang.kegiatan.type
                    if(id === "3"){
                        obj["rm"]["belanja_barang"]["kegiatan"][type] = parseInt(num)
                        objTfoot["rm"]["belanja_barang"]["kegiatan"][type] += parseInt(num)
                    }
                    // id 4 rm.belanja_modal.type
                    if(id === "4"){
                        obj["rm"]["belanja_modal"][type]  = parseInt(num)
                        objTfoot["rm"]["belanja_modal"][type]  += parseInt(num)
                    }
                    // id 5 blu.belanja_modal.type
                    if(id === "5"){
                        obj["blu"]["belanja_modal"][type] = parseInt(num)
                        objTfoot["blu"]["belanja_modal"][type] += parseInt(num)
                    }
                    // id 6 blu.belanja_barang.type
                    if(id === "6"){
                        obj["blu"]["belanja_barang"][type] = parseInt(num)
                        objTfoot["blu"]["belanja_barang"][type] += parseInt(num)
                    }
                }
            }
            
            objTfoot["total"] += obj["total"]        
            let index = 0
            for(let d of data){
                if(d.kodesatker === r.kodesatker){
                    data[index]["result"] = obj
                }
                index++
            }
        }
        
        this.setState({ data: data, total: objTfoot })
    }
    getGrafik = (type="") => {
        rawResponse = []
        if(source){
            source.cancel()
        }
        source = axios.CancelToken.source()
        let config = { cancelToken: source.token}
        const data = this.state.data 
        for(let d of data){
            grafikPosturTabel({ param: "type=" + type, target: d.sik_url, apikey: d.sik_apikey }).then((res) => this.responseTabel(res, d))
        }
    }
    render(){
        const tfoot = this.state.total
        return (
            
            <div>
                
                <div className="w-[100%] pt-2">
                    <Tabs 
                        type = { this.props.type }
                        handleType = { this.props.handleType }
                    />
                    
            <div className="p-2 bg-white dark:bg-slate-800 rounded-md mt-2 text-sm">
                <FormRadio 
                    handleData = { this.handleData }
                    data       = { this.state.type }
                />
            <div className="overflow-auto">
                <table className="w-full">
                    <thead>
                        <tr>
                            <ColHead rowSpan={ 4 } className="bg-slate-600 dark:bg-slate-700 rounded-l-md border-l-none">
                                No
                            </ColHead>
                            <ColHead rowSpan={ 4 } className="bg-slate-600 text-center dark:bg-slate-700">
                                Satker
                            </ColHead>
                            <ColHead colSpan={ 4 } className="bg-indigo-700 dark:bg-indigo-700 text-center">
                                Rupiah Murni (RM)
                            </ColHead>
                            <ColHead colSpan={2} className="bg-emerald-600 dark:bg-emerald-600 text-center">
                                Badan Pelayanan Umum (BLU)
                            </ColHead>

                            <ColHead rowSpan={3} className="bg-blue-600 dark:bg-blue-700 text-center rounded-r-md">
                                Total
                            </ColHead>
                        </tr>
                        <tr>
                            <ColHead rowSpan={ 2 } className="bg-indigo-700 dark:bg-indigo-700 text-center">
                                Belanja Pegawai
                            </ColHead>
                            <ColHead colSpan={2} className="bg-indigo-700 dark:bg-indigo-700 text-center">
                                Belanja Barang
                            </ColHead>
                            <ColHead rowSpan={2}  className="bg-indigo-700 dark:bg-indigo-700 text-center">
                                Belanja Modal
                            </ColHead>
                            <ColHead rowSpan={2}  className="bg-emerald-600 dark:bg-emerald-600 text-center">
                                Belanja Barang
                            </ColHead>
                            <ColHead rowSpan={2} className="bg-emerald-600 dark:bg-emerald-600 text-center">
                                Belanja Modal
                            </ColHead>
                        </tr>
                        <tr>
                            <ColHead  className="bg-indigo-700 dark:bg-indigo-700 text-center">
                                Operasional
                            </ColHead> 
                            <ColHead  className="bg-indigo-700 dark:bg-indigo-700 text-center">
                                Kegiatan
                            </ColHead>    
                        </tr>
                        
                    </thead>
                    <tbody>
                        {
                            this.state.data.map((x, i) => {
                                return (
                                    <tr key={ i } className={ `${i%2 !== 0 ? "bg-slate-50 dark:bg-slate-700" : ""}` }>
                                        <ColBody>{ i+1 }</ColBody>
                                        <ColBody className="whitespace-nowrap">{ x.namasatker } </ColBody>
                                        {
                                            x.result !== undefined ?
                                                <Fragment>
                                                    <ColBody className="text-right">{ numberFormat(x.result.rm.belanja_pegawai.pagu) }</ColBody>
                                                    

                                                    <ColBody className="text-right">{ numberFormat(x.result.rm.belanja_barang.operasional.pagu) }</ColBody>
                                                    
                                                    
                                                    <ColBody className="text-right">{ numberFormat(x.result.rm.belanja_barang.kegiatan.pagu) } </ColBody>
                                                    
                                                    <ColBody className="text-right">{ numberFormat(x.result.rm.belanja_modal.pagu) } </ColBody>
                                                    
                                                    
                                                    <ColBody className="text-right">{ numberFormat(x.result.blu.belanja_barang.pagu) } </ColBody>
                                                    
                                                    
                                                    <ColBody className="text-right">{ numberFormat(x.result.blu.belanja_modal.pagu) } </ColBody>
                                                    
                                                    <ColBody className="text-right">{ numberFormat(x.result.total) } </ColBody>
                                                </Fragment>
                                            :
                                            null
                                        }
                                    </tr>
                                )
                            })
                        }
                    </tbody>
                    <thead>
                        <tr>
                            <ColBody className="bg-slate-600 dark:bg-slate-700 rounded-l-md border-l-none">

                            </ColBody>
                            <ColBody className="bg-slate-600 text-center dark:bg-slate-700">
                        
                            </ColBody>
                            <ColHead  className="bg-indigo-700 dark:bg-indigo-700 text-center">
                                { numberFormat(tfoot.rm.belanja_pegawai.pagu) }
                            </ColHead> 
                            

                            <ColHead  className="bg-indigo-700 dark:bg-indigo-700 text-center">
                                {numberFormat(tfoot.rm.belanja_barang.operasional.pagu)}
                            </ColHead> 
                            
                            <ColHead  className="bg-indigo-700 dark:bg-indigo-700 text-center">
                                {numberFormat(tfoot.rm.belanja_barang.kegiatan.pagu)}
                            </ColHead> 
                            
                            <ColHead  className="bg-indigo-700 dark:bg-indigo-700 text-center">
                                {numberFormat(tfoot.rm.belanja_modal.pagu)}
                            </ColHead> 
                            
                            <ColHead  className="bg-emerald-600 dark:bg-emerald-600 text-center">
                                {numberFormat(tfoot.blu.belanja_barang.pagu)}
                            </ColHead> 
                            
                            <ColHead  className="bg-emerald-600 dark:bg-emerald-600 text-center">
                                {numberFormat(tfoot.blu.belanja_modal.pagu)}
                            </ColHead> 
                            <ColHead rowSpan={4} className="bg-blue-600 dark:bg-blue-700 text-right rounded-r-md">
                                {numberFormat(tfoot.total)}
                            </ColHead>
                        </tr>
                    </thead>
                </table>
            </div>

        </div>
                </div>
            </div>
        )
    }
}

const ColHead = ({ children, colSpan="", rowSpan="", className }) => {
    return  <td colSpan={ colSpan } rowSpan={ rowSpan } className={`text-slate-50 px-5 py-2 border-[1px] border-slate-100 dark:border-slate-900  ${className}`}>
        { children }
    </td>
                        
}
const ColBody = ({ className, children }) => {
    return  <td className={`text-slate-700 px-5 py-2 border-[1px] border-slate-100 dark:border-slate-900 dark:text-slate-50 ${className}`}>
        { children }
    </td>
}

const FormRadio = ({ handleData, data }) => {
    return (
        <div className="p-3 flex gap-3">
            <div className="font-bold text-slate-600 dark:text-slate-50">
                Chart
            </div>
            <div className="flex text-sm gap-2 mb-1 cursor-pointer" onClick={ () => handleData("data", "pagu_awal") }>
                <div className={`w-4 h-4 rounded-full ${ data == "pagu_awal" ? "bg-blue-500 border-blue-500 dark:bg-blue-600 dark:border-blue-600" : "bg-slate-200 border-slate-300 dark:bg-slate-700 dark:border-slate-600" } border-[1px] `}></div>
                <div className="text-slate-800 dark:text-slate-50">Pagu Awal</div>
            </div>

            <div className="flex text-sm gap-2 mb-1 cursor-pointer" onClick={ () => handleData("data", "pagu_akhir") }>
                <div className={`w-4 h-4 rounded-full ${ data == "pagu_akhir" ? "bg-blue-500 border-blue-500 dark:bg-blue-600 dark:border-blue-600" : "bg-slate-200 border-slate-300 dark:bg-slate-700 dark:border-slate-600" } border-[1px] `}></div>
                <div className="text-slate-800 dark:text-slate-50">Pagu Akhir</div>
            </div>

        </div>
        
    )
}
export default Datatabel