import { useEffect, useState } from 'react'
import { EnvelopeIcon, PhoneIcon,InformationCircleIcon, MoonIcon } from '@heroicons/react/24/solid'
import { SunIcon } from '@heroicons/react/24/outline'
import { FaceBook, Twitter, Youtube, Instagram } from './Components/Icons/SocialMedia'
import { Danger } from './Components/Form/Alerts'
import { Username, Password } from './Components/Form/Input'
import { LoginButton } from './Components/Form/Button'
import { Login as LoginHit, CheckLogin, GetUrlGoogle } from './Api/Auth'
import { backend, siteUrl, handleBgLogin} from './Api/Value'
import  {ModalOpen} from './Components/Container/Modal'
const paramMessage =() => {
    const theUrl = window.location.href 
    const object = new URL(theUrl).searchParams
    const message = object.get("m") 
    
    try {
        return message ? atob(message) : ""    
    } catch (error) {
        return  ""
    }
    
}
const Login = ({ toggleDark, isDark, isLogin }) => {

    const [ message, setMessage ]  = useState(paramMessage())
    const [ isOpen, setIsOpen ] = useState(false)
    const [ googleUrl, setGoogleUrl ] = useState("")
    const getUrl = () => {
        GetUrlGoogle().then((res) => {
            if(res.code === 200){
                
                setGoogleUrl(res.res.data.url)
            }
        })
    }
    const openOauth = () => {
        if(googleUrl !== ""){
            window.location.href = googleUrl
            

        }
        setIsOpen(false)
    }
    useEffect(() => {
        getUrl()
        handleBgLogin(isDark)
        setTimeout(() => {
            setMessage("")
        }, 20000);
        return () => {};
    }, []);

    return (
        <div>
            <div className="bg-white sm:bg-white/50 dark:bg-slate-800 p-4 rounded-md fixed bottom-5 lg:bottom-[unset] lg:top-5 right-5 z-10 ">
                {
                    isDark === false ?
                        <SunIcon className="w-6 text-slate-600" onClick={ toggleDark } />
                    :
                        <MoonIcon className="w-6 dark:text-yellow-400 text-slate-600" onClick={ toggleDark } />
                }
            </div>
        <div id="bg-login"  className="h-screen w-screen grid grid-cols-1 content-center ">
            
            <div className="flex justify-center">
                <div className="sm:grid sm:grid-cols-2 max-w-[900px]">
                    <div className="bg-white dark:bg-slate-900 rounded-l-xl p-12 hidden sm:block">
                        <center>
                            <img src={ `${siteUrl}/esdm.png` } className="w-16" alt="" />                    
                            <div className="text-sm m-2 text-slate-600 dark:text-slate-50 font-bold">Badan Pengembangan <br/> Sumber Daya Manusia ESDM</div>
                            <div className="text-xs text-slate-500 dark:text-slate-200">Kementerian Energi dan Sumber Daya Mineral</div>
                            <div className="italic text-slate-500 text-xs mt-2 dark:text-slate-200">Jujur, Professional, Melayani, Inovatif, Berarti</div>

                            <div className="font-extrabold text-transparent  bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600 lg:text-7xl mt-10 sm:text-5xl">DEIS 3.0</div>
                            <div className="text-[13.3px] text-slate-500 dark:text-slate-400">Dashboard Executice Information System</div>

                            <div className="mt-10 flex gap-4 justify-center">
                                <a href="mailto:humasinfo.bpsdm@esdm.go.id">
                                    <EnvelopeIcon className="h-5 w-5 text-slate-500 dark:text-slate-200"  />                            
                                </a>
                                <a href="https://facebook.com/BPSDMESDM">
                                    <FaceBook isDark={ isDark }/>
                                </a>
                                <a href="https://twitter.com/bpsdmesdm">
                                    <Twitter isDark={ isDark }/>                            
                                </a>
                                <a href="https://www.youtube.com/channel/UC5A8o5rpdmXrYz4XrtLtBKg">
                                    <Youtube isDark={ isDark }/>                            
                                </a>
                                <a href="https://instagram.com/bpsdm_esdm">
                                    <Instagram isDark={ isDark }/>                            
                                </a>
                                <a href="tel:+0215254508">
                                    <PhoneIcon className="h-4 w-4 text-slate-500 dark:text-slate-200" />                            
                                </a>
                            </div>
                        </center>
                    </div>
                    <div className="p-8 rounded-r-xl h-[99vh] sm:h-[unset] dark:bg-black/40  bg-white bg-opacity-50 backdrop-blur-xl pt-10">
                            {
                                isLogin === "wait" ?
                                    <div className="text-center pt-[30%]">Mohon Tunggu</div>
                                :
                                <form action={`${backend}/api/login`} method="POST">
                                    <center>
                                        <img src={ `${siteUrl}/esdm.png` } className="w-20 sm:hidden mb-2" alt="" />
                                        <div className="font-extrabold text-transparent text-3xl sm:hidden bg-clip-text bg-gradient-to-r from-purple-600 to-blue-600 ">DEIS 3.0</div>
                                    </center>
                                        <div className="text-slate-800 hidden sm:block dark:text-slate-50 font-bold text-center text-2xl">LOGIN</div>
                                        <div className="text-slate-700 dark:text-slate-50 text-sm text-center mb-2">Mohon Login Terlebih Dahulu</div>            
                                    <div >
                                        <div>
                                                <Danger message={ message  } />
                                        </div>
                                        
                                    </div>
                                    <div className="mb-3">
                                        <Username  name="username"/>                    
                                    </div>
                                    
                                        <Password  name="password"/>
                                    
                                    <div className="h-[30px]">
                                    
                                    </div>
                                    <LoginButton />
                                        <div className="text-center py-4">atau</div>
                                    <button onClick={ () => setIsOpen(true) } type="button" className="bg-white dark:bg-black dark:text-slate-50 rounded-md mt-2  w-full py-2 flex justify-center">
                                        <div className="pt-[2px]">
                                            <img src={`${siteUrl}/google.png`} className="w-4" alt="" />                                        
                                        </div>
                                        <div className="text-sm">
                                            &nbsp; &nbsp;Sign in with Google                                    
                                        </div>
                                    </button>
                                </form>
                            }
                            

                    </div>
                </div>
            </div>
            
        </div>

            <ModalOpen 
                isOpen={ isOpen }
                closeModal = {() => setIsOpen(false) }
                title="Fitur ini digunakan untuk pengembangan SSO dengan deis 2.0. Tidak bisa digunakan selain oleh developer"
                onSubmit={ () => openOauth() }
                submitText="Lanjutkan"
            >
                
            </ModalOpen>
        </div>

    )
}

const Root = ({ toggleDark, isDark  }) => {

    const [ isLogin, setIsLogin ] = useState("wait")
    
    const check = async () => {
        const r = await CheckLogin()
        if(r.code === 200){
            if(r.res.message === "belum login"){
                setIsLogin("belum")
            }else if(r.res.message === "sudah login"){
                
                if(`${backend}`.indexOf("localhost") === -1){
                    window.location.replace("https://deis.bpsdm.id")
                }
                
            }
        }
    }

    useEffect(() => {
        check()
        return () => {
            
        };
    }, []);
 
    return <Login toggleDark={ toggleDark } isDark={ isDark } isLogin={ isLogin } />
}

export default Root