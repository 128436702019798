import axios from 'axios'
import { handleResponse } from './Value'
const SIK_KEY = "Tklg7p35VQtaIOdDfXighvolgti64YMP"
export const tabelPenyerapan = ({ target, param, apiKey, config }) => {
    if(param === ""){
        return
    }
    let form = new FormData()
    form.set("key", apiKey)
    
   return handleResponse(axios.post(`${target}/tablePenyerapan?${param}`, form, config))
}

export const aparaturSend = ({ param }) => {
    
    let form = new FormData()
    form.set("key", SIK_KEY)
    return handleResponse(axios.post("https://ika.bpsdm.id/aparatur/index.php?/deis/tablePenyerapan?mingguKe=" + param, form), false)
}
export const RealisasiPerKonkin = ({ target, param, apiKey }) => {
    
    let form = new FormData()
    form.set("key", apiKey)
    return handleResponse(axios.post(`${target}/realisasiPerKonkin?${param}`, form))
}
export const ListTransaksi = ({ target, param, apiKey }) => {
    let form = new FormData()
    form.set("key", apiKey)
    return handleResponse(axios.post(`${target}/listTransaksi?${param}`, form))
}
export const TabTransaksi = ({ target, param, apiKey }) => {
    let form = new FormData()
    form.set("key", apiKey)
    return handleResponse(axios.post(`${target}/tabTransaksi?${param}`, form))
}
export const listKonkin = ({ target, apikey }) => {
    let form = new FormData()
    form.set('key', apikey)
    return handleResponse(axios.post(`${target}/listKonkin`, form))   
}
export const grafikLine = ({ target, apikey, param, config }) =>{
    let form = new FormData()
    form.set('key', apikey)
    return handleResponse(axios.post(`${target}/grafikLine?${param}`, form, config))    
}

export const grafikBar = ({ target, apikey, param, config }) =>{
    let form = new FormData()
    form.set('key', apikey)
    return handleResponse(axios.post(`${target}/grafikBar?${param}`, form, config))    
}
export const grafikTable = ({ target, apikey, config }) =>{
    let form = new FormData()
    form.set('key', apikey)
    return handleResponse(axios.post(`${target}/grafikTabel`, form, config))    
}
export const grafikPostur = ({ target, apikey, config }) =>{
    let form = new FormData()
    form.set('key', apikey)
    return handleResponse(axios.post(`${target}/grafikPostur`, form, config))    
}
export const grafikPosturTabel = ({ target, apikey, config, param }) =>{
    let form = new FormData()
    form.set('key', apikey)
    return handleResponse(axios.post(`${target}/grafikPosturTabel?${param}`, form, config))    
}