
import { Component, useRef, useEffect } from 'react'
import { SwitchButton } from '../Form/Button'
import { SelectIcon } from '../Form/Input'
import { RectangleStackIcon } from '@heroicons/react/24/outline'

import { getGradient, listBulan, milyard } from '../../Api/Value'
import { getAllSatker } from '../../Api/Satker'
import { listKonkin, grafikLine } from '../../Api/Deis'
import { ResponsiveLine } from '@nivo/line'
import Line from './Rechart/Line'
import LineTabel from './Tabel/LineTabel'
import axios from 'axios'
let rawResponse = []
let source      = null
let rawKonkin   = []
let counterKonkin= 0
class LineChartPenyerapan extends Component{
    constructor(props){
        super(props)
        this.state  = {
            satker: {
            },
            chart: {
                target: false,
                realisasi: false
            },
            konkin: [],
            data: "kumulatif",
            data_2: "",

            
            minVal: 0,
            maxVal: 0,
            ready: false,
            dataLine:{
                kumulatif: [],
                non_kumulatif: []
            }
        }
    }
    componentDidMount = () => {
        this.onLoad();
    }
    onLoad = async () => {
    
        const r = await getAllSatker()
        if(r.code === 200){
            let satker = {}
            
            for(let a of r.res.data){
                satker[`_${a.kodesatker}`] = { namasatker: a.namasatker,checked: false, kodesatker: a.kodesatker, url: a.sik_url, api_key: a.sik_apikey }
            }
            this.setState({
                satker: satker
            }, () => {
                this.getGrafik()
            })
        }
    }
    getGrafik = (data=this.state.data) => {
            rawResponse = []
            if(source){
                source.cancel()
            }
            source = axios.CancelToken.source()
            let config = { cancelToken: source.token}
            this.setState({ ready: false })
            const satker = this.state.satker
          
            const data_2 = this.state.data_2
            const konkin = this.state.konkin.filter((x) => x.checked).map((x) => {
                return x.kk
            })
            const max = Object.getOwnPropertyNames(satker)
            // cek apakah ada yang di checked
            let cek   = false
            let maximumReq = 0
            for(let c of max){
                if(satker[c].checked === true){
                    cek = true
                    maximumReq++
                }
            }
            for(let a of max){
                if(cek === false){
                    grafikLine({ config: config, target: satker[a]["url"], apikey: satker[a]["api_key"], param: `konkin=${btoa(JSON.stringify(konkin))}&sumber_dana=${data_2}` })
                    .then((res) => this.responseGrafik(res, max.length, data))
                }else if(cek === true && satker[a].checked === true){
                    grafikLine({ config: config, target: satker[a]["url"], apikey: satker[a]["api_key"], param: `konkin=${btoa(JSON.stringify(konkin))}&sumber_dana=${data_2}` })
                    .then((res) => this.responseGrafik(res, maximumReq, data))
                }
            }
       
    }
    responseGrafik = (res, panjangRequest) => {
        if(res.code === 200){
            rawResponse.push(JSON.stringify(res.res.data))
            if(rawResponse.length === panjangRequest){
                this.refreshLine()
            }
        }else{
            rawResponse.push(false)
            if(rawResponse.length === panjangRequest){
                this.refreshLine()
            }
        }
    }
    refreshLine = () => {
        
        const listType    = ["kumulatif", "non_kumulatif"]
        let data          = { kumulatif: [], non_kumulatif: [] };
        for(let f of rawResponse){
            if(f){
                const theData = JSON.parse(f)
              
                for(let type of listType){
                    // preventing reference
                    let total = 0
                    for (let bt = 0; bt < 12; bt++) {
                        const element = theData[type][bt];
                        total += (element["Realisasi"] + element["Target"] + element["Target Semula"])
                    }
                    // skipped
                    if(total === 0){
                        continue;
                    }
                    for (let b = 0; b < 12; b++) {
                        const dataBulan = theData[type][b]
                        // jika data belum ada
                        if(data[type][b] === undefined){
                            
                            data[type][b] = dataBulan
                        }else{
                            data[type][b]["Deviasi"]       += (dataBulan["Deviasi"])
                            data[type][b]["Realisasi"]     += (dataBulan["Realisasi"])
                            data[type][b]["Target"]        += (dataBulan["Target"])
                            data[type][b]["Target Semula"] += (dataBulan["Target Semula"])
                        }
                    }   
                }
            }
        }
        const m = new Date().getMonth()
        // potong to undefined => Realisasi
        for(let type of listType){
            data[type] = data[type].map((x, i) => {
                x["Deviasi Persen"]   = (x["Deviasi"] == 0 || x["Target"] == 0) ? 0 : ((x["Deviasi"] / x["Target"])*100).toFixed(2)
                x["Realisasi Persen"] = (x["Realisasi"] == 0 || x["Target"] == 0) ? 0 : ((x["Realisasi"] / x["Target"])*100).toFixed(2)
                x["Deviasi"]        = parseFloat(milyard(x["Deviasi"]))
                if(i > m){
                    delete x["Realisasi"]
                    delete x["Realisasi Persen"]
                }else{
                    x["Realisasi"]      = parseFloat(milyard(x["Realisasi"]))
                }
                x["Target"]         = parseFloat(milyard(x["Target"]))
                x["Target Semula"]  = parseFloat(milyard(x["Target Semula"]))
                return x 
            })    
        }
    
        this.setState({
            dataLine: JSON.parse(JSON.stringify(data)),
            ready: true
        })
        
        
    }
    handleToggle = (key) => {
        if(this.state.ready === true){
            let satker = this.state.satker 
            satker[key]["checked"] = !satker[key]["checked"] 
            this.setState({ satker: satker, konkin: [] }, () => {
                this.cariKonkin()
                this.getGrafik()
            })
        }
    }
    responseKonkin = (res, url, max) => {
        if(res.code == 200){
            let kk = res.res.data.map((x) => ({ kk: x, url: url,  checked: false }))
            
            for(let k of kk){
                rawKonkin.push(k)
            }
            counterKonkin++
            if(counterKonkin === max){
                this.setState({
                    konkin: rawKonkin
                })
            }
        }
    }
    cariKonkin = () => {
        rawKonkin =  []
        counterKonkin = 0
        let satker = this.state.satker 
        let own    = Object.getOwnPropertyNames(satker)
        let max    = 0 
        for(let k of own){
            const val = satker[k]
            if(val.checked){
                max++
            }
        }
        for(let o of own){
            const val = satker[o]
            if(val.checked){
                listKonkin({ target: val.url, apikey: val.api_key }).then((r) => this.responseKonkin(r, val.url, max))
            }
        }
    }
    handleKonkin = (kk) => {
        if(this.state.ready === true){
            let konkin = this.state.konkin 
            let index = 0 
            for(let k of konkin){
                if(k.kk == kk){
                    konkin[index]["checked"] = (konkin[index]["checked"]) ? false : true
                }
                index++
            }
            this.setState({
                konkin: konkin
            }, () => {
                this.getGrafik()
            })
        }
    }
    handleData = (type, target) => {
        if(this.state.ready === true){
            if(type == "data"){
                this.setState({ data: target })
            }else if(type == "data_2"){
                if (target === this.state.data_2) {
                    
                }else{
                    this.setState({ data_2: target }, () => this.getGrafik())
                }
            }
        }
    }
    render(){
        const darking = this.props.isDark ? {
                        background: "#1E293B",
                        grid: { line: { stroke: "#475569" } },
                        legends: { text: { fill: "#F8FAFC" } },
                        axis: { legend: { text: { fill: "#F8FAFC" } }, ticks: { text: { fill:  "#F8FAFC" } } },
                        crosshair:{ line: { stroke: "#F8FAFC" } }
                    } : undefined
        const satker = this.state.satker
        
        return (
            <div className="md:flex gap-2">
                <div className="md:w-[30%] xl:grid xl:grid-cols-2 gap-2 content-start pt-2">
                    <div className="mb-2 xl:mb-0">
                        <div className="bg-white/60 dark:bg-black/50 dark:text-slate-50 rounded-t-md px-3 py-2 text-center text-sm">
                            Satker                    
                        </div>
                        <div className="bg-white dark:bg-slate-800 p-3 rounded-b-md h-96 pl-4">

                            
                            {
                                Object.getOwnPropertyNames(satker).map((x) => {
                                    return (
                                        <Slider key={x} title={ satker[x]["namasatker"] } enabled={ satker[x]["checked"] } setEnabled={ () => this.handleToggle(x) } />
                                    )
                                })
                            }        
                            
                        </div>
                    </div>

                    <div className="mb-2 xl:mb-0">
                        <div className="bg-white/60 dark:bg-black/50 dark:text-slate-50 rounded-t-md px-3 py-2 text-center text-sm">
                            Konkin                    
                        </div>
                        <div className="bg-white dark:bg-slate-800 p-3 rounded-b-md ">
                            <div className="h-[360px] overflow-auto ">
                            {
                                this.state.konkin.length === 0 ?
                                    <div className="text-sm italic dark:text-slate-50 text-slate-600 text-center">
                                        Select Satker                       
                                    </div>
                                :
                                    this.state.konkin.map((x) => {
            
                                        return (
                                            <Slider key={x.kk} title={ x.kk } enabled={ x.checked } setEnabled={ () => this.handleKonkin(x.kk) } />
                                        )
                                    })
                            }
                            </div>
                            
                        </div>
                    </div>
                    
                    <div className="mb-2  xl:mb-0">
                        <div className="bg-white/60  dark:bg-black/50 dark:text-slate-50 rounded-t-md px-3 py-2 text-center text-sm">
                            Data                    
                        </div>
                        <div className="bg-white p-3 dark:bg-slate-800 rounded-b-md h-24">
                            <FormRadioKumulatif 
                                data = { this.state.data }
                                handleData = { this.handleData }
                            />
                        </div>
                    </div>

                    <div className="mb-2 xl:mb-0">
                        <div className="bg-white/60  dark:bg-black/50 dark:text-slate-50 rounded-t-md px-3 py-2 text-center text-sm">
                            Data 2
                        </div>
                        <div className="bg-white p-3 dark:bg-slate-800 rounded-b-md h-24">
                            <FormRadio 
                                data = { this.state.data_2 }
                                handleData = { this.handleData }
                            />
                        </div>
                    </div>
                    {/*
                    <div className="col-span-2 mb-2 xl:mb-0">
                        <div>
                            <div className="bg-white/60 dark:bg-black/50 dark:text-slate-50 rounded-t-md px-3 py-2 text-center text-sm">
                                Layer
                            </div>
                            <div className="bg-white dark:bg-slate-800 p-4 rounded-b-md ">
                                <div className="text-slate-800 text-sm mb-2 dark:text-slate-50">Layer 1</div>
                                <SelectIcon Icon={ RectangleStackIcon }>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                </SelectIcon>

                                <div className="text-slate-800 text-sm mb-2 mt-2 dark:text-slate-50">Layer 2</div>
                                <SelectIcon Icon={ RectangleStackIcon }>
                                    <option value="2022">2022</option>
                                    <option value="2023">2023</option>
                                </SelectIcon>
                            </div>
                        </div>
                    </div>
                        */}
                </div>


                <div className="mt-2 w-full md:w-[70%] ">
                    <div className="bg-white dark:bg-slate-800 dark:text-slate-800 rounded-md relative p-3">
                        <div >
                            <Line 
                                data = { 
                                    this.state.data == "kumulatif" ?
                                        this.state.dataLine.kumulatif 
                                    :
                                        this.state.dataLine.non_kumulatif
                                }
                                isDark ={ this.props.isDark }
                            />
                        </div>
                    </div>
                    <div>
                        <LineTabel 
                                data ={ this.state.dataLine }
                                isDark ={ this.props.isDark }

                        />
                    </div>

                </div>
            </div>
            
        )
    }
}

const App = ({ isDark }) => {
    
    return <LineChartPenyerapan isDark={isDark}/>
}
const FormRadio = ({ handleData, data }) => {
    
    return (
        <div>
            <div className="flex text-sm gap-2 mb-1 cursor-pointer" onClick={ () => handleData("data_2", "") }>
                <div className={`w-4 h-4 rounded-full ${ data == "" ? "bg-blue-500 border-blue-500 dark:bg-blue-600 dark:border-blue-600" : "bg-slate-200 border-slate-300 dark:bg-slate-700 dark:border-slate-600" } border-[1px] `}></div>
                <div className="text-slate-800 dark:text-slate-50">All</div>
            </div>

            <div className="flex text-sm gap-2 mb-1 cursor-pointer" onClick={ () => handleData("data_2", "RM") }>
                <div className={`w-4 h-4 rounded-full ${ data == "RM" ? "bg-blue-500 border-blue-500 dark:bg-blue-600 dark:border-blue-600" : "bg-slate-200 border-slate-300 dark:bg-slate-700 dark:border-slate-600" } border-[1px] `}></div>
                <div className="text-slate-800 dark:text-slate-50">RM</div>
            </div>

            <div className="flex text-sm gap-2 cursor-pointer" onClick={ () => handleData("data_2", "BLU") }>
            <div className={`w-4 h-4 rounded-full ${ data == "BLU" ? "bg-blue-500 border-blue-500 dark:bg-blue-600 dark:border-blue-600" : "bg-slate-200 border-slate-300 dark:bg-slate-700 dark:border-slate-600" } border-[1px] `}></div>
                <div className="text-slate-800 dark:text-slate-50">BLU</div>
            </div>
        </div>
        
    )
}

const FormRadioKumulatif = ({ handleData, data }) => {
    return (
        <div>
            <div className="flex text-sm gap-2 mb-1 cursor-pointer" onClick={ () => handleData("data", "kumulatif") }>
                <div className={`w-4 h-4 rounded-full ${ data == "kumulatif" ? "bg-blue-500 border-blue-500 dark:bg-blue-600 dark:border-blue-600" : "bg-slate-200 border-slate-300 dark:bg-slate-700 dark:border-slate-600" } border-[1px] `}></div>
                <div className="text-slate-800 dark:text-slate-50">Kumulatif</div>
            </div>

            <div className="flex text-sm gap-2 mb-1 cursor-pointer" onClick={ () => handleData("data", "non_kumulatif") }>
                <div className={`w-4 h-4 rounded-full ${ data == "non_kumulatif" ? "bg-blue-500 border-blue-500 dark:bg-blue-600 dark:border-blue-600" : "bg-slate-200 border-slate-300 dark:bg-slate-700 dark:border-slate-600" } border-[1px] `}></div>
                <div className="text-slate-800 dark:text-slate-50">Non Kumulatif</div>
            </div>

        </div>
        
    )
}
const Slider = ({ title, enabled, setEnabled }) => {
    return (
        <div className="flex text-sm gap-2 mb-1">
            <div className="w-[46px]">
                <SwitchButton enabled={ enabled } setEnabled={ setEnabled }  />        
            </div>
            <div className="w-full">
                { title }
            </div>
        </div>
    )
}
export default App