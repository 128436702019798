import { UserIcon, KeyIcon, EyeIcon, EyeSlashIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { SwitchButton } from './Button'
export const Username = ({ name }) => {
    return (
        <div className="relative mt-2">
            <UserIcon className="w-5 absolute mt-2 ml-2 text-slate-500 dark:text-slate-200" />
            <input placeholder="NIP" name={ name } id={ name } type="text" className="bg-white dark:autofill:shadow-[inset_0_0_0px_1000px_rgb(0,0,0)] dark:autofill:text-fill-gray-100 dark:autofill:text-white  dark:bg-slate-800 border-none w-full rounded-md pl-10 text-slate-800 dark:text-slate-50" />        
        </div>
    )
}
export const TextArea = ({ defaultValue, name, className, onChange }) => {
    return (
        <textarea 
            id={name}
            onChange = { onChange }
            defaultValue = { defaultValue }
            name={ name } 
            className={`bg-slate-50 text-sm border-[1px] border-slate-200 dark:border-slate-600 dark:autofill:shadow-[inset_0_0_0px_1000px_rgb(0,0,0)] dark:autofill:text-fill-gray-100 dark:autofill:text-white  dark:bg-slate-800 w-full rounded-md pl-2 text-slate-800 dark:text-slate-50 ${className}`}  rows="3"></textarea>
    )
}
export const Text = ({ Icon, placeholder, onChange, type="text", disabled =false, className,name, defaultValue="" }) => {
    const [ show, setShow ] = useState(false)
    return (
        <div className="relative">
            {
                Icon ? 
                    <Icon className="w-5 absolute mt-2 ml-2 text-slate-500 dark:text-slate-200" />
                : 
                    null
            }
            <input 
            onChange = { onChange }
            disabled ={ disabled}
            defaultValue={ defaultValue }
            name={ name }
            id={name}
            
            autoComplete="off"
            placeholder={ placeholder } 
            type={(show === true && type === "password") ? "text" : type }
            className={`bg-slate-50 text-sm border-[1px] border-slate-200 dark:border-slate-600 dark:autofill:shadow-[inset_0_0_0px_1000px_rgb(0,0,0)] dark:autofill:text-fill-gray-100 dark:autofill:text-white  dark:bg-slate-800 w-full rounded-md pl-10 text-slate-800 dark:text-slate-50 ${className}`} />        
            {
                (type === "password" && show === false) ?
                    <EyeSlashIcon onClick={ () => setShow(!show) } className="w-5 h-5 absolute right-2 top-[12px] text-slate-700"/>
                :
                (type === "password" && show === true) ?
                    <EyeIcon onClick={ () => setShow(!show) } className="w-5 h-5 absolute right-2 top-[12px] text-slate-700"/>
                :
                null
            }
        </div>
    )
}
export const Select = ({ children, onChange, defaultValue="", name="", value=false }) => {
    let param = { }
    if(value){
        param = { value: value }
    }
    return (
        <div>
            <select onChange={ onChange } { ...param } defaultValue={ defaultValue } name={ name } id={ name } className="bg-slate-50 text-sm border-[1px] border-slate-200 dark:border-slate-600 dark:autofill:shadow-[inset_0_0_0px_1000px_rgb(0,0,0)] dark:autofill:text-fill-gray-100 dark:autofill:text-white  dark:bg-slate-800 w-full rounded-md pl-3 text-slate-800 dark:text-slate-50">
                { children }
            </select>
            
        </div>
    )
}
export const SelectIcon = ({ children, onChange, Icon, defaultValue="", value, name="" }) => {
    let param = { }
    if(value){
        param = { value: value }
    }
    return (
        <div className="relative">
            <div>
                <select onChange={ onChange } name={ name } { ...param } defaultValue={ defaultValue } className="bg-slate-50 border-[1px] border-slate-200 dark:border-slate-600 text-sm  pl-9 dark:bg-slate-800 w-full rounded-md text-slate-800 dark:text-slate-50">
                    { children }
                </select>
                <Icon 
                    className="w-5 h-5 text-slate-500 absolute top-[9px] left-[7px]"
                />
            </div>
        </div>
    )
}

export const Password = ({ name }) => {
    const [hide, setHide] = useState(true)
    return (
        <div className="relative mt-2">
            <KeyIcon className="w-5 absolute mt-2 ml-2 text-slate-500 dark:text-slate-200" />
            {
                hide === true ?
                    <EyeIcon onClick={ () => setHide(false) } className="w-5 absolute right-3 mt-2 text-slate-500 dark:text-slate-200"/>
                :
                    <EyeSlashIcon onClick={ () => setHide(true) } className="w-5 absolute right-3 mt-2 text-slate-500 dark:text-slate-200"/>
            }
            <input autoComplete="new-password" placeholder="Password" id={ name } name={ name } type={ hide ? "password" : "text"} className="bg-white dark:autofill:shadow-[inset_0_0_0px_1000px_rgb(0,0,0)] dark:autofill:text-fill-gray-100 dark:bg-slate-800 border-none w-full rounded-md pl-10 text-slate-800 dark:text-slate-50" />        
        </div>
    )
}

export const Star = () => {
    return <span className="text-red-500"> *</span>
}

export const Slider = ({ title, enabled, setEnabled }) => {
    return (
        <div className="flex text-sm gap-2 mb-1">
            <div className="whitespace-nowrap">
                { title }
            </div>
            <div className="w-[46px]">
                <SwitchButton enabled={ enabled } setEnabled={ setEnabled }  />        
            </div>
        
        </div>
    )
}