import axios from 'axios'
import { handleResponse } from './Value'
import { backend } from './Value'
const headers = { "headers": { "content-type": "application/json" } } 
export const Login = ({ username, password }) => {
    const params = JSON.stringify({
        username: username,
        password: password
    })
    return handleResponse(axios.post(`${backend}/api/login`, params, headers))
}

export const CheckLogin = () => {
    return handleResponse(axios.get(`${backend}/api/user/check`))
}

export const GetUrlGoogle = () => {
    return handleResponse(axios.get(`${backend}/api/auth/google/url`))
}